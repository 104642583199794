// React Imports
import { FC, useState } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import { Outlet } from "react-router-dom";

// Component Imports
import SideBar from "../SideBar";
import Header from "../Header";

interface LayoutProps {}

const Layout: FC<LayoutProps> = (props) => {
  const [isdashboard, setIsdashboard] = useState(true);
  const [menuexpand, setMenuexpand] = useState(false);

  return (
    <Box
      data-testid="layout-ui"
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: "65px",
        }}
      >
        <SideBar
          isdashboard={isdashboard}
          setIsdashboard={setIsdashboard}
          menuexpand={menuexpand}
          setMenuexpand={setMenuexpand}
        />
      </Box>
      <Box
        sx={{
          width: "100vw",
        }}
        onClick={() => {
          setMenuexpand(false);
        }}
      >
        {menuexpand && <Box className="layloading" />}
        <Header
          isdashboard={isdashboard}
          setIsdashboard={setIsdashboard}
          menuexpand={menuexpand}
          setMenuexpand={setMenuexpand}
        />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
