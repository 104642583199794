// React Imports
import { FC } from 'react';

// UI Imports
import { Box, Chip, Stack, Typography } from '@mui/material'

// Functional Imports

// Component Imports

interface SkillmatrixProps {
  skills?:any;
}

const Skillmatrix: FC<SkillmatrixProps> = (props) => {
 const skillarr = JSON.parse(props.skills)
  return (<Box 
    className="skillmatrix"
    data-testid="skillmatrix-page"
    sx={{
      border: "1px solid #e6e6e6",
      borderRadius:"5px",
      p: 2,
      boxShadow:1
    }}>
    <Typography  sx={{
      mb:2,
    }}><strong>Candidate Skills</strong></Typography>
    <Stack direction="row" spacing={1} sx={{display:'inline-block'}}>
      
    {skillarr.Skills.map(function(i:any){
        return  <Chip key={i} label={i} variant="outlined" />;
    })}
     
     


    </Stack>
  </Box>);
};

export default Skillmatrix;