const Paths = {
  CREATE_PASSWORD: "/create-password",
  EMPLOYER_ACCOUNT_CREATE_PASSWORD: "/employer/account/create-password",
  CONTACT_US: "/contact-us",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",

  // Authenticated Routes
  SLASH: "/",
  APP: "/app",
  APPLICATIONS: "/applications",
  APPLICATIONDETAIL: "/view-application",
  APP_ID: "/:appid",
  PROPOSE_TIME_SLOTS: "/proposetimeslots",
  JOBS: "/jobs",
  JOB: "/job",
  PROJECTS: "/projects",
  PROJECT: "/project",
  DASHBOARD: "/home",
  USERS: "/users",
  USER:"/user",
  USEREDIT:"/useredit",
  // subroute
  CREATE_JOB: "/create-job",
  EDIT_JOB: "/edit-job",
  VIEW_JOB: "/view-job",
  JOB_ID: "/:jobid",
  USER_ID: "/:userid",
};

export default Paths;
