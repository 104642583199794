// React Imports
import { FC } from "react";

// Router Imports
import { Navigate, Route, Routes as Router } from "react-router-dom";
import Paths from "./Paths";

// Page Imports
import Viewapplication from "../components/ApplicationDetails";
import Applications from "../components/Applications";
import CreateJob from "../components/CreateJob";
import AllJobs from "../components/AllJobs";
import EditJob from "../components/EditJob";
import ViewJob from "../components/ViewJob";
import Layout from "../components/Layout";
import ProjectListing from "../components/ProjectListing";
import Dashboardlayout from "../components/Dashboard/Dashboardlayout";
import Users from "../components/Users";
import Userview from "../components/Useroverview";
import Useredit from "../components/Useroverview/Useredit";
import ProjectDetails from "../components/ProjectDetails";

interface AuthenticatedRoutesProps {}

const AuthenticatedRoutes: FC<AuthenticatedRoutesProps> = (props) => {
  return (
    <div data-testid="authenticated-routes">
      <Router>
        <Route element={<Layout />}>
          <Route path={Paths.APPLICATIONS} element={<Applications />} />
          <Route
            path={Paths.APPLICATIONS + Paths.APP_ID}
            element={<Viewapplication />}
          />
          <Route path={Paths.JOBS} element={<AllJobs />} />
          <Route path={Paths.JOB + Paths.CREATE_JOB} element={<CreateJob />} />
          <Route
            path={Paths.JOB + Paths.EDIT_JOB + Paths.JOB_ID}
            element={<EditJob />}
          />
          <Route
            path={Paths.JOB + Paths.VIEW_JOB + Paths.JOB_ID}
            element={<ViewJob />}
          />
          <Route path={Paths.USER + Paths.USER_ID} element={<Userview />} />
          <Route path={Paths.USEREDIT + Paths.USER_ID} element={<Useredit />} />
          <Route path={Paths.PROJECTS} element={<ProjectListing />} />
          <Route
            path={Paths.PROJECT + "/:id"}
            element={<ProjectDetails />}
          />
          <Route path={Paths.DASHBOARD} element={<Dashboardlayout />} />
          <Route path={Paths.USERS} element={<Users />} />
        </Route>
        <Route path={"*"} element={<Navigate to={Paths.DASHBOARD} />} />
      </Router>
    </div>
  );
};

export default AuthenticatedRoutes;
