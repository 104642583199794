// React Imports
import { FC } from "react";

// UI Imports
import { ButtonBase, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../Text";

interface ButtonFilledProps {
  sx?: SxProps;
  title?: string;
  onClick?: any;
  testId?: string;
  disabled?:boolean;
}

const ButtonFilled: FC<ButtonFilledProps> = (props) => {
  return (
    <ButtonBase
      data-testid={props.testId}
      onClick={props.onClick}
      disabled={props.disabled}
      sx={{
        "&.Mui-disabled": {
          background: "#eaeaea",
          color: "#c0c0c0"
        },
        padding: "8px 16px",
        width: "auto",
        borderRadius: 1,
        backgroundColor: Colors.Blue7,
        ...props.sx,
      }}
    >
      <Text fontSize={16} fontWeight={500} color={Colors.White} style={{ lineHeight: "20px"}}>
        {props.title}
      </Text>
    </ButtonBase>
  );
};

export default ButtonFilled;
