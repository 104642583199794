// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps } from "@mui/material";

// Functional Imports

// Component Imports
import Text from "../Text";

interface AttributeValueProps {
  label?: string;
  value?: string;
  sx?: SxProps;

  testId?: string;
}

const AttributeValue: FC<AttributeValueProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        pl: 4,
        width: "18%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        height: "100%",
        ...props.sx,
      }}
    >
      <Text fontWeight={400} fontSize={14}>
        {props.label}
      </Text>
      <Text fontWeight={700} fontSize={14}>
        {props.value}
      </Text>
    </Box>
  );
};

export default AttributeValue;
