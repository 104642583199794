// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Box, IconButton, Modal, Paper } from "@mui/material";
import { IoIosClose } from "react-icons/io";

// Functional Imports
import { object, string } from "yup";
import { useFormik } from "formik";
import API from "../../api/API";
import Toasts from "../../utils/Toasts";
import Colors from "../../utils/Colors";
import Functions from "../../utils/Functions";

// Component Imports
import ButtonFilled from "../../common/ButtonFilled";
import LabelSwitch from "../../common/LabelSwitch/LabelSwitch";
import LabelInput from "../../common/LabelInput";
import Text from "../../common/Text";

interface AddUserPopupProps {
  open: boolean;
  setOpen?: any;
  updateDataAgain?: boolean;
  setUpdateDataAgain?: any;
  isTesting?: boolean;
}

const AddUserPopup: FC<AddUserPopupProps> = (props) => {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      emailId: "",
      status: "Active",
    },
    enableReinitialize: true,
    validationSchema: object({
      firstName: string().required("This field is required"),
      lastName: string().required("This field is required"),
      emailId: string()
        .required("This field is required")
        .email("Please enter valid email ID"),
    }),
    onSubmit: (values) => {
      handleAddUser(values);
    },
  });

  useEffect(() => {
    if (props.isTesting) {
      closeAndReset();
      handleAddUser({});
      handleStatus();
    }
  }, []);

  const closeAndReset = () => {
    formik.resetForm();
    props.setOpen(false);
  };

  const handleAddUser = (values: any) => {
    const payload = {
      firstName: Functions.ValueRetrieve.toCamelCase(values.firstName),
      lastName: Functions.ValueRetrieve.toCamelCase(values.lastName),
      emailId: values.emailId?.toLowerCase(),
      status: values.status,
      role: ["Admin"],
    };

    API.addUser(payload).then((response) => {
      if (response.status === 400) {
        Toasts.error(response.message?.appStatusDescription);
      } else {
        Toasts.success("User has been created successfully!");
        props.setOpen(false);
        props.setUpdateDataAgain(!props.updateDataAgain);
        closeAndReset();
      }
    });
  };

  const handleStatus = () => {
    let newStatus = "Active";

    if (formik.values.status === "Active") {
      newStatus = "Inactive";
    }

    formik.setFieldValue("status", newStatus);
  };

  return (
    <Modal
      open={props.open}
      onClose={closeAndReset}
      sx={{
        display: "flex",
        border: "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        data-testid="add-user-popup"
        sx={{
          p: 4,
          border: "none",
          width: 840,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fontSize={20} fontWeight={700}>
            Add User
          </Text>
          <IconButton
            data-testid="close-btn"
            sx={{
              mr: -1.25,
              p: 0,
            }}
            onClick={closeAndReset}
          >
            <IoIosClose size={48} />
          </IconButton>
        </Box>

        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LabelInput
            label={"First Name"}
            required={true}
            labelTextColor={Colors.Black1}
            textFieldProps={{
              inputProps: {
                "data-testid": "first-name-input",
                onKeyDown: Functions.Validation.restrictToOnlyAlphabets,
                style: {
                  backgroundColor: Colors.Grey15,
                },
              },
              sx: {
                width: 360,
                mt: 1,
              },
              id: "firstName",
              name: "firstName",
              value: Functions.ValueRetrieve.toCamelCase(
                formik.values.firstName
              ),
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: Functions.Sonar.booleanAnd(
                formik.touched?.firstName,
                Boolean(formik.errors?.firstName)
              ),
              helperText: Functions.Sonar.booleanAndString(
                formik.touched?.firstName,
                formik.errors?.firstName
              ),
            }}
          />
          <LabelInput
            label={"Last Name"}
            required={true}
            labelTextColor={Colors.Black1}
            textFieldProps={{
              inputProps: {
                "data-testid": "last-name-input",
                onKeyDown: Functions.Validation.restrictToOnlyAlphabets,
                style: {
                  backgroundColor: Colors.Grey15,
                },
              },
              sx: {
                width: 360,
                mt: 1,
              },
              id: "lastName",
              name: "lastName",
              value: Functions.ValueRetrieve.toCamelCase(
                formik.values.lastName
              ),
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: Functions.Sonar.booleanAnd(
                formik.touched?.lastName,
                Boolean(formik.errors?.lastName)
              ),
              helperText: Functions.Sonar.booleanAndString(
                formik.touched?.lastName,
                formik.errors?.lastName
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <LabelInput
            label={"Email ID"}
            labelTextColor={Colors.Black1}
            required={true}
            textFieldProps={{
              inputProps: {
                "data-testid": "email-input",
                style: {
                  backgroundColor: Colors.Grey15,
                },
              },
              sx: {
                width: 360,
                mt: 1,
              },
              id: "emailId",
              name: "emailId",
              value: formik.values.emailId,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: Functions.Sonar.booleanAnd(
                formik.touched?.emailId,
                Boolean(formik.errors?.emailId)
              ),
              helperText: Functions.Sonar.booleanAndString(
                formik.touched?.emailId,
                formik.errors?.emailId
              ),
            }}
          />
          <LabelInput
            label={"Role"}
            required
            labelTextColor={Colors.Black1}
            textFieldProps={{
              disabled: true,
              inputProps: {
                "data-testid": "role-input",
                style: {
                  backgroundColor: Colors.Grey15,
                },
              },
              sx: {
                width: 360,
                mt: 1,
              },
              value: "Admin",
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LabelSwitch
            label={"Status"}
            labelTextColor={Colors.Black1}
            required
            sx={{
              width: 360,
            }}
            switchText={formik.values.status}
            switchProps={{
              checked: formik.values.status === "Active",
              onChange: handleStatus,
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 3,
          }}
        >
          <ButtonFilled
            title={"Create User"}
            testId="confirm-btn"
            onClick={() => formik.handleSubmit()}
          />
        </Box>
      </Paper>
    </Modal>
  );
};

export default AddUserPopup;
