// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useParams } from "react-router-dom";
import API from "../../api/API";
import Paths from "../../routes/Paths";
import ENUMS from "../../utils/Enums";
import Colors from "../../utils/Colors";
import Functions from "../../utils/Functions";

// Component Imports
import LabelInputReadOnly from "../../common/LabelInputReadOnly";
import StatusChipJobs from "../AllJobs/StatusChipJobs";
import AttributeValue from "../../common/AttributeValue";
import ButtonFilled from "../../common/ButtonFilled";
import TabSelector from "../../common/TabSelector";
import Breadcrumbs from "../../common/Breadcrumbs";
import Loader from "../../common/Loader";
import Text from "../../common/Text";

interface ProjectDetailsProps {}

const ProjectDetails: FC<ProjectDetailsProps> = (props) => {
  const { id } = useParams();

  const [projectDetailData, setProjectDetailData] = useState(
    projectDetailDataInit
  );
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getProjectDetailsByID(String(id))
      .then((response) => {
        setDataLoading(false);
        setProjectDetailData(response.entity);
      })
      .catch((e) => {
        setDataLoading(false);
      });
  }, [id]);

  return (
    <Box
      data-testid="project-details-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "scroll",
      }}
    >
      {dataLoading && <Loader />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 1,
            pb: 1,
          }}
          path={[
            { path: Paths.DASHBOARD, name: "Home" },
            { path: Paths.PROJECTS, name: "Projects" },
            { name: projectDetailData.projectId },
          ]}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1.5,
        }}
      >
        <Text
          fontSize={20}
          fontWeight={600}
          style={{
            marginTop: 16,
            marginLeft: 32,
          }}
        >
          Details
        </Text>
        <Box
          sx={{
            gap: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mr: 5,
          }}
        >
          <ButtonFilled title="Change Rate" />
          <ButtonFilled title="End Project" />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.White,
          height: 75,
          width: "95%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mx: 4,
          mt: 2,
        }}
      >
        <AttributeValue
          label="Project name"
          value={projectDetailData.projectName}
        />
        <AttributeValue
          label="Project ID"
          value={projectDetailData.projectId}
        />
        <AttributeValue
          label="Start Date"
          value={Functions.DateFunctions.formatDateObjectOrNA(
            projectDetailData.startDate
          )}
        />
        <AttributeValue
          label="End Date"
          value={Functions.DateFunctions.formatDateObjectOrNA(
            projectDetailData.endDate,
            "MM/DD/YYYY"
          )}
        />
      </Box>
      <TabSelector
        options={[
          ENUMS.PROJECT_TAB_OPTIONS.PROJECT_DETAILS,
          ENUMS.PROJECT_TAB_OPTIONS.TIMESHEET,
          ENUMS.PROJECT_TAB_OPTIONS.INVOICES,
          ENUMS.PROJECT_TAB_OPTIONS.DOCUMENTS,
          ENUMS.PROJECT_TAB_OPTIONS.REQUEST_SUBMISSIONS,
        ]}
        selectedOption={ENUMS.PROJECT_TAB_OPTIONS.PROJECT_DETAILS}
      />
      <Box
        sx={{
          backgroundColor: Colors.White,
          width: "95%",
          mx: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            pt: 3,
          }}
        >
          <LabelInputReadOnly
            label="Worker Name"
            value={projectDetailData.employeeName}
          />
          <LabelInputReadOnly
            label="Bill rate"
            value={Functions.Sonar.returnStringWithDollarNotNull(
              projectDetailData.billRate
            )}
          />
          <LabelInputReadOnly
            label="OT Bill rate"
            value={Functions.Sonar.returnStringWithDollarNotNull(
              projectDetailData.otBillRate
            )}
          />
          <LabelInputReadOnly
            label="Account Manager"
            value={projectDetailData.accountManager}
          />
        </Box>
        <Box
          sx={{
            width:
              projectDetailData.projectStatus === ENUMS.JOB_STATUS_TYPES.ACTIVE
                ? 115
                : 125,
            px: 3,
            pb: 3,
          }}
        >
          <Text>Status</Text>
          <StatusChipJobs
            style={{
              marginTop: 8,
            }}
            type={projectDetailData.projectStatus}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectDetails;

const projectDetailDataInit = {
  projectDetailId: "",
  projectId: "",
  projectName: "",
  employeeName: "",
  startDate: null,
  endDate: null,
  projectStatus: "",
  billRate: null,
  otBillRate: null,
  accountManager: "",
};
