// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps } from "@mui/material";

// Functional Imports

// Component Imports
import TabTile from "./TabTile";

interface TabSelectorProps {
  options: string[];
  selectedOption?: string;
  setSelectedOption?: any;
  sx?: SxProps;

  testId?: string;
}

const TabSelector: FC<TabSelectorProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        mt: 2,
        ml: 4,
        ...props.sx,
      }}
    >
      {props.options.map((item) => (
        <TabTile
          key={item}
          label={item}
          selected={props.selectedOption === item}
          onClick={() => {
            if (props.setSelectedOption) {
              props.setSelectedOption(item);
            }
          }}
        />
      ))}
    </Box>
  );
};

export default TabSelector;
