// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps } from "@mui/material";

// Functional Imports

// Component Imports

interface ImageBoxProps {
  src?: string;
  sx?: SxProps;
}

const ImageBox: FC<ImageBoxProps> = (props) => {
  return (
    <Box
      component={"img"}
      src={props.src}
      sx={{
        ...props.sx,
      }}
    />
  );
};

export default ImageBox;
