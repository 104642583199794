// React Imports
import { CSSProperties, FC } from "react";

// UI Imports

// Functional Imports
import Colors from "../../utils/Colors";
import ENUMS from "../../utils/Enums";

// Component Imports
import Text from "../../common/Text";

interface StatusChipJobsProps {
  type?: string;
  testId?: string;
  style?: CSSProperties;
}

const StatusChipJobs: FC<StatusChipJobsProps> = (props) => {
  let backgroundColor;
  let borderColor;
  let textColor;

  if (props.type === ENUMS.JOB_STATUS_TYPES.INACTIVE) {
    borderColor = Colors.Red2;
    backgroundColor = Colors.LightRed;
    textColor = Colors.Red3;
  } else if (props.type === ENUMS.JOB_STATUS_TYPES.ACTIVE) {
    borderColor = Colors.Green2;
    backgroundColor = Colors.LightGreen;
    textColor = Colors.Green4;
  } else if (props.type === ENUMS.JOB_STATUS_TYPES.IN_REVIEW) {
    textColor = Colors.DarkBlue2;
    borderColor = Colors.Blue1;
    backgroundColor = Colors.LightBlue2;
  }

  return (
    <Text
      testId={props.testId}
      style={{
        backgroundColor: backgroundColor,
        border: "1px solid " + borderColor,
        padding: "2px 12px 2px 12px",
        borderRadius: 16,
        ...props.style,
      }}
      color={textColor}
    >
      {props.type}
    </Text>
  );
};

export default StatusChipJobs;
