// React Imports
import { FC, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// UI Imports
import { Box, Grid } from "@mui/material";
import STRINGS from "../../utils/Strings";
import Colors from "../../utils/Colors";

// Functional Imports
import Toasts from "../../utils/Toasts";
import Paths from "../../routes/Paths";
import API from "../../api/API";

// Component Imports
import ButtonFilled from "../../common/ButtonFilled";
import Breadcrumbs from "../../common/Breadcrumbs";
import LabelInput from "../../common/LabelInput";
import Loader from "../../common/Loader";
import Text from "../../common/Text";

interface UserviewProps {
  isTesting?: boolean;
}

const Userview: FC<UserviewProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>({
    id: "",
    firstname: "",
    lastname: "",
    emailId: "",
    isEmailEditable: "",
    status: "",
    role: "",
    userId: "",
  });

  const Params = useParams();
  const navigate = useNavigate();
  const userid = Params.userid;

  useEffect(() => {
    if (props.isTesting) {
      getuserdetail();
    }
    getuserdetail();
  }, []);
  const getuserdetail = () => {
    setLoading(true);
    API.getUserByID(userid)
      .then((response) => {
        if (response?.status === 200) {
          let data = response?.entity;
          setUserData({
            firstname: data?.firstName,
            lastname: data?.lastName,
            emailId: data?.emailId,
            isEmailEditable: data?.isEmailEditable,
            status: data?.status,
            role: data?.role[0],
            userId: data?.userId,
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        Toasts.error(e);
        setUserData({});
        setLoading(false);
      });
  };

  return (
    <Box
      data-testid="view-user-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "scroll",
      }}
    >
      {loading && <Loader />}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 2,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
          }}
          path={[
            { path: Paths.DASHBOARD, name: "Home" },
            { path: Paths.USERS, name: "Users" },
            { name: userData?.firstname + " " + userData?.lastname },
          ]}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          p: 4,
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          position: "relative",
        }}
      >
        <Text fontWeight={700} fontSize={24}>
          {STRINGS.COMMONSTR.DETAIL}
        </Text>
        <ButtonFilled
          title={STRINGS.COMMONSTR.EDIT}
          onClick={() => {
            navigate(Paths.USEREDIT + "/" + Params.userid);
          }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.White,
          ml: 4,
          mr: 4,
          p: 2,
          pl: 4,
          height: "72vh",
        }}
      >
        <Box
          sx={{
            height: 60,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fontWeight={700} fontSize={16}>
            {STRINGS.CREATE_JOB.REQUIRED_DETAIL}
          </Text>
        </Box>
        <Box
          className="detailsection"
          sx={{
            width: "100%",
            mb: 8,
          }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={"First Name"}
                required={true}
                inputProps={{
                  readOnly: true,
                }}
                textFieldProps={{
                  variant: "standard",
                  sx: {
                    width: { xs: "20%", sm: 275 },
                  },
                  inputProps: {
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      fontWeight: 700,
                      color: Colors.Black1,
                    },
                  },
                  value: userData.firstname,
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={"Last Name"}
                required={true}
                inputProps={{
                  readOnly: true,
                }}
                textFieldProps={{
                  variant: "standard",
                  sx: {
                    width: { xs: "20%", sm: 275 },
                  },
                  inputProps: {
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      fontWeight: 700,
                      color: Colors.Black1,
                    },
                  },
                  value: userData?.lastname,
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={"Name"}
                required={true}
                inputProps={{
                  readOnly: true,
                }}
                textFieldProps={{
                  variant: "standard",
                  sx: {
                    width: { xs: "20%", sm: 275 },
                  },
                  inputProps: {
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      fontWeight: 700,
                      color: Colors.Black1,
                    },
                  },
                  value: userData?.firstname + " " + userData?.lastname,
                }}
              />
            </Grid>

            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={"Email ID"}
                required={true}
                inputProps={{
                  readOnly: true,
                }}
                textFieldProps={{
                  variant: "standard",
                  sx: {
                    width: { xs: "20%", sm: 275 },
                  },
                  inputProps: {
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      fontWeight: 700,
                      color: Colors.Black1,
                    },
                  },
                  value: userData?.emailId,
                }}
              />
            </Grid>

            <Grid item xs={3} md={3} mt={3}>
              <Box
                sx={{
                  width: "17%",
                  mr: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.3,
                  }}
                >
                  <Text color={Colors.Grey9}>Status</Text>
                  {<Text color={Colors.Red}>*</Text>}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flax-start",
                    alignItems: "center",
                    p: 0,
                    mt: 1,
                  }}
                >
                  <div className={userData?.status}>
                    <Text fontWeight={400} fontSize={14}>
                      {userData?.status}
                    </Text>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={"Role"}
                required={true}
                inputProps={{
                  readOnly: true,
                }}
                textFieldProps={{
                  variant: "standard",
                  sx: {
                    width: { xs: "20%", sm: 275 },
                  },
                  inputProps: {
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      fontWeight: 700,
                      color: Colors.Black1,
                    },
                  },
                  value: userData?.role,
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} mt={3}></Grid>
            <Grid item xs={3} md={3} mt={3}></Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Userview;
