const REGEX_TYPES = {
  PASSWORD: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
  ATLEAST_ONE_UPPERCASE_AND_ONE_LOWERCASE: /(?=.*[a-z])(?=.*[A-Z])/,
  MINIMUM_8_CHARACTER_MAXIMUM_16_CHARACTER: /^(?=.*\w).{8,16}$/,
  AT_LEAST_1_NUMBER: /\d/,
  SPECIAL_CHARACTER: /(?=.*[!@#$%?=*&])/,
  PASSWORD2:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
  EMAIL: /^\S+@\S+\.\S+$/,
  NO_SPECIAL_CHARACTER: /^[a-zA-Z0-9]{4,10}$/,
  VALID_NAME: /^[A-Za-z ]*$/,
};

const CONSTANTS = {
  REGEX: REGEX_TYPES,
};

export default CONSTANTS;
