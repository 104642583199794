// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps } from "@mui/material";

// Functional Imports
import Images from "../../utils/Images";
import STRINGS from "../../utils/Strings";

// Component Imports
import Text from "../../common/Text";

interface EmptyListProps {
  label?: string;
  sx?: SxProps;
}

const EmptyList: FC<EmptyListProps> = (props) => {
  return (
    <Box
      data-testid="empty-list-ui"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 8,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          width: 620,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          component={"img"}
          src={Images.EmptyApplicationsList}
          sx={{ height: 100 }}
        />
        <Text fontSize={24} fontWeight={700}>
          {STRINGS.EMPTY_LIST.NO_RESULTS_FOUND}
        </Text>
      </Box>
    </Box>
  );
};

export default EmptyList;
