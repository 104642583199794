// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Box, IconButton, Modal, Paper } from "@mui/material";
import { IoIosClose } from "react-icons/io";

// Functional Imports
import { object } from "yup";
import { useFormik } from "formik";
import Colors from "../../utils/Colors";
import STRINGS from "../../utils/Strings";

// Component Imports
import LabelInput from "../../common/LabelInput";
import DateSelect from "../../common/DateSelect";
import Text from "../../common/Text";
import ButtonFilled from "../../common/ButtonFilled";

interface EndProjectPopupProps {
  open: boolean;
  setOpen: any;
  updateDataAgain?: boolean;
  setUpdateDataAgain?: any;

  isTesting?: boolean;
}

const EndProjectPopup: FC<EndProjectPopupProps> = (props) => {
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: object({}),
    onSubmit: (values) => {},
  });

  useEffect(() => {
    if (props.isTesting) {
      closeAndReset();
    }
  }, []);

  const closeAndReset = () => {
    formik.resetForm();
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={closeAndReset}
      sx={{
        display: "flex",
        border: "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        data-testid="end-project-popup"
        sx={{
          p: 4,
          border: "none",
          width: 840,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fontSize={20} fontWeight={700}>
            End Project
          </Text>
          <IconButton
            data-testid="close-btn"
            sx={{
              mr: -1.25,
            }}
            onClick={closeAndReset}
          >
            <IoIosClose />
          </IconButton>
        </Box>

        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LabelInput
            label={"Worker Name"}
            textFieldProps={{
              variant: "standard",
              sx: {
                width: 360,
                mt: 0.5,
              },
              inputProps: {
                style: {
                  fontWeight: 700,
                },
                sx: {
                  backgroundColor: Colors.White,
                },
                disabled: true,
              },
              value: "Amanda Thurston",
            }}
          />
          <LabelInput
            label={"Project Name"}
            textFieldProps={{
              variant: "standard",
              sx: {
                width: 360,
                mt: 0.5,
              },
              inputProps: {
                style: {
                  fontWeight: 700,
                },
                sx: {
                  backgroundColor: Colors.White,
                },
                disabled: true,
              },
              value: "Project 01",
            }}
          />
        </Box>

        <Box
          sx={{
            mt: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DateSelect
            label={STRINGS.HIRE_POPUP.PROJECT_START_DATE}
            required
            textFieldProps={{
              inputProps: {
                "data-testid": "project-start-date-input",
                style: {
                  fontWeight: 700,
                  backgroundColor: Colors.Grey15,
                },
              },
              sx: {
                width: 360,
                mt: 1,
              },
            }}
          />
        </Box>

        <LabelInput
          label="Comments"
          required
          sx={{
            mt: 3,
          }}
          textFieldProps={{
            multiline: true,
            InputProps: {
              sx: {
                padding: 0,
              },
            },
            rows: 3,
            inputProps: {
              "data-testid": "comments-input",
              style: {
                backgroundColor: Colors.Grey15,
                fontWeight: 400,
                padding: 8,
              },
            },
          }}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 3,
          }}
        >
          <ButtonFilled
            title={"Submit Request"}
            testId="confirm-btn"
            onClick={() => {
              formik.handleSubmit();
            }}
          />
        </Box>
      </Paper>
    </Modal>
  );
};

export default EndProjectPopup;
