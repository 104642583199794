import ActionTypes from "../action";

const initialState = {
  jobTitleData: [],
  jobLocationData: [],
  employmentTypeData: [],
  jobStatusData: [],
  hiringManagerData: [],
  noOfApplicationData: [],
  jobPostedData: [],
  jobTitleFilters: [],
  jobLocationFilters: [],
  employmentTypeFilters: [],
  jobStatusFilters: [],
  hiringManagerFilters: [],
  noOfApplicationFilters: [],
  jobPostedFilters: [],
  pagination: {
    pageSize: 10,
    pageIndex: 0,
  },
  showActiveJobs: false,
  sortModel: [],
  typeOfView: "list",
};

const jobFilterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.UPDATE_JOB_STATE:
      return action.payload;
    default:
      return state;
  }
};

export default jobFilterReducer;