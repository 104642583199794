// React Imports
import { FC } from "react";

// UI Imports
import { ButtonBase, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../Text";

interface TabTileProps {
  label?: string;
  selected?: boolean;
  onClick?: any;
  sx?: SxProps;
}

const TabTile: FC<TabTileProps> = (props) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      sx={{
        backgroundColor: props.selected ? Colors.White : Colors.LightBlue7,
        height: 40,
        px: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        mr: 0.5,
        ...props.sx,
      }}
    >
      <Text fontWeight={props.selected ? 600 : 400} fontSize={16}>
        {props.label}
      </Text>
    </ButtonBase>
  );
};

export default TabTile;
