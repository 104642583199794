// React Imports
import { FC } from "react";

// UI Imports
import { Box, Switch, SwitchProps, Tooltip } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../Text";
import styled from "@emotion/styled";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "100ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: Colors.Blue7,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: Colors.Blue7,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {},
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
  },
}));

interface ActiveJobsSwitchProps {
  label?: string;
  switchProps?: SwitchProps;
  tooltipTitle?: string;

  testId?: string;
}

const ActiveJobsSwitch: FC<ActiveJobsSwitchProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mr: 2,
      }}
    >
      <Text fontSize={16}>{props.label}</Text>
      <Tooltip arrow placement="top" title={props.tooltipTitle}>
        <Box>
          <IOSSwitch
            inputProps={{
              //@ts-ignore
              "data-testid": props.testId,
            }}
            sx={{
              ml: 1,
            }}
            {...props.switchProps}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ActiveJobsSwitch;
