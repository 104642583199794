// React Imports
import { FC } from "react";

// UI Imports
import { Box, IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports

interface ActionTileDashboardProps extends Partial<GridRenderCellParams> {
  handleClick?: any;

  isTesting?: boolean;
}

const ActionTileDashboard: FC<ActionTileDashboardProps> = (props) => {
  return (
    <Box
      data-testid="action-tile-job-list"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0,
        position: "relative",
      }}
    >
      <IconButton
        sx={{
          borderRadius: 2,
        }}
        data-testid="menu-btn"
        onClick={(ev) => props.handleClick(ev)}
      >
        <IoEllipsisHorizontalSharp size={20} color={Colors.Black1} />
      </IconButton>
    </Box>
  );
};

export default ActionTileDashboard;
