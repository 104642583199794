import React, { useCallback, useEffect, useState } from 'react'
import { Divider, Grid, List, ListItem, ToggleButton, ToggleButtonGroup, Typography, Box } from '@mui/material'
import { DateCalendar, LocalizationProvider, PickersDay } from '@mui/x-date-pickers'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ChevronLeft, ChevronRight, Clock } from 'lucide-react';
import dayjs, { Dayjs } from 'dayjs';
import API from '../../api/API';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useNavigate } from 'react-router-dom';
import Paths from '../../routes/Paths';

interface scheduledData {
    id: string;
    currentAvailabilities: {
        id: string;
        interviewDate: string;
        interviewTime: string;
        interviewDuration: string;
        isFinalized: boolean;
    }[];
    candidateName: string;
    submissionId: string;
}

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore)

interface InterviewSidebarProps {
    isTesting?: boolean;
}

const ServerDay = (props: any) => {
    const { day, highlightedDays, minDate, ...other } = props;
    const isHighlighted = highlightedDays.some((d: any) => d.isSame(day, 'day'));
    const isBeforeMinDate = day.isBefore(minDate, 'day');
    const isFutureDate = day.isAfter(dayjs(), 'day');

    return (
        <PickersDay
            {...other}
            day={day}
            disabled={!isHighlighted || isBeforeMinDate}
            sx={{
                ...(isHighlighted && !isBeforeMinDate ? {
                    backgroundColor: '#ECEFFF',
                } : {}),
                ...(isFutureDate && !isHighlighted ? {
                    color: "#353535 !important"
                } : {}),
            }}
        />
    );
};

const InterviewSidebar: React.FC<InterviewSidebarProps> = (props) => {
    const navigate = useNavigate();
    const [viewMode, setViewMode] = useState<'D' | 'W'>('W');
    const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
    const [scheduledData, setScheduledData] = useState<any[]>([]);
    const [highlightedDays, setHighlightedDays] = useState<Dayjs[]>([]);
    const [minDate] = useState<Dayjs>(dayjs().subtract(1, 'month').startOf('month'));
    const [canGoBack, setCanGoBack] = useState(true);


    useEffect(() => {
        setCanGoBack(currentDate.isAfter(minDate));
    }, [currentDate, minDate]);



    const sampleScheduledData = [{
        id: "1",
        currentAvailabilities: [
            { id: "a1", interviewDate: "2023-10-20", interviewTime: "10:00:00", interviewDuration: "30", isFinalized: true }
        ],
        candidateName: "John Doe",
        submissionId: "abcd"
    }];

    const mockDate = dayjs('2024-10-01');
    const mockGroupedInterviews = {
        '2024-09-30': [{ interviewData: 'Interview 1' }],
        '2024-10-07': [{ interviewData: 'Interview 2' }],
    };

    useEffect(() => {
        if (props.isTesting) {
            fetchScheduledInterviewData();
            updateHighlightedDays();
            findNextAvailableDate("abcd", "1234");
            findNextHighlightedDay("abcd");
            findPreviousHighlightedDay("abcd");
            handleNext();
            handlePrevious();
            groupInterviews(sampleScheduledData);
            renderInterviews();
            findPreviousHighlightedWeek(dayjs('2024-10-15'));
            findNextHighlightedWeek(dayjs('2024-10-15'));
            findNextAvailableWeek(mockDate, mockGroupedInterviews);
            handleInterviewRedirect("abcd");
        }
    }, []);

    const fetchScheduledInterviewData = async () => {
        try {
            const data = await API.getScheculeInterviewDashboard();
            if (data && data.entityList) {
                setScheduledData(data.entityList);
            } else {
                console.error('Failed to fetch interview data. No data returned.');
            }
        } catch (error) {
            console.error('Error fetching interview data:', error);
        }
    };

    useEffect(() => {
        fetchScheduledInterviewData();
    }, [])

    const updateHighlightedDays = () => {
        const days = scheduledData.reduce((acc: Dayjs[], interview) => {
            const finalizedAvailability = interview.currentAvailabilities.find((a: any) => a.isFinalized);
            if (finalizedAvailability) {
                const interviewDate = dayjs(finalizedAvailability.interviewDate).startOf('day');
                if (!acc.some(d => d.isSame(interviewDate, 'day'))) {
                    acc.push(interviewDate);
                }
            }
            return acc;
        }, []);
        setHighlightedDays(days);
    };

    useEffect(() => {
        updateHighlightedDays();
    }, [currentDate, scheduledData]);

    const getGroupingKey = (interviewDate: dayjs.Dayjs, viewMode: string): string => {
        return viewMode === 'D'
            ? interviewDate.format('YYYY-MM-DD')
            : interviewDate.startOf('week').format('YYYY-MM-DD');
    };

    const groupInterviews = useCallback((scheduledData: scheduledData[]) => {
        const shouldIncludeInterview = (interviewDate: dayjs.Dayjs, viewMode: string, currentDate: dayjs.Dayjs): boolean => {
            if (viewMode === 'D') {
                return interviewDate.isSameOrAfter(currentDate.startOf('day'));
            } else {
                return interviewDate.isSameOrAfter(currentDate.startOf('day')) &&
                    interviewDate.isBefore(currentDate.endOf('week').add(1, 'week'));
            }
        };
    
        return scheduledData.reduce((acc: Record<string, scheduledData[]>, interview) => {
            const finalizedAvailability = interview.currentAvailabilities.find((a: any) => a.isFinalized);
            if (finalizedAvailability) {
                const interviewDate = dayjs(finalizedAvailability.interviewDate).startOf('day');
    
                if (shouldIncludeInterview(interviewDate, viewMode, currentDate)) {
                    const key = getGroupingKey(interviewDate, viewMode);
    
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(interview);
                    acc[key].sort((a, b) => {
                        const dateA = dayjs(a.currentAvailabilities[0].interviewDate);
                        const dateB = dayjs(b.currentAvailabilities[0].interviewDate);
                        return dateA.diff(dateB);
                    });
                }
            }
            return acc;
        }, {});
    }, [viewMode, currentDate]);
    

    const handlePrevious = useCallback(() => {
        if (viewMode === 'D') {
            const prevDate = findPreviousHighlightedDay(currentDate);

            if (!prevDate || prevDate.isBefore(minDate) || prevDate.isSame(currentDate)) {
                setCanGoBack(false);
            } else {
                setCurrentDate(prevDate);
                setCanGoBack(true);
            }
        } else {
            const prevDate = findPreviousHighlightedWeek(currentDate);
            if (prevDate.isBefore(minDate)) {
                setCurrentDate(minDate);
                setCanGoBack(false);
            } else {
                setCurrentDate(prevDate);
                setCanGoBack(true);
            }
        }
    }, [viewMode, currentDate, highlightedDays, minDate]);



    const handleNext = useCallback(() => {
        const nextDate = viewMode === 'D'
            ? findNextHighlightedDay(currentDate)
            : findNextHighlightedWeek(currentDate);
        setCurrentDate(nextDate);
    }, [viewMode, currentDate, highlightedDays]);

    const findPreviousHighlightedDay = (date: any) => {
        const sortedDays = [...highlightedDays].sort((a, b) => a.valueOf() - b.valueOf());
        const reversedDays = [...sortedDays].reverse();
        const previousDay = reversedDays.find(d => d.isBefore(date, 'day')) || date;
        return previousDay;
    };


    const findNextHighlightedDay = (date: any) => {
        const sortedDays = [...highlightedDays].sort((a, b) => a.valueOf() - b.valueOf());
        const nextDay = sortedDays.find(d => d.isAfter(date, 'day')) || date;
        return nextDay;
    };


    const findPreviousHighlightedWeek = (date: any) => {
        const currentWeekStart = date.startOf('week');
        const prevWeek = currentWeekStart.subtract(1, 'week');
        return highlightedDays
            .filter(d => d.isBefore(currentWeekStart))
            .sort((a, b) => b.valueOf() - a.valueOf())[0] || prevWeek;
    };

    const findNextHighlightedWeek = (date: any) => {
        const nextWeekStart = date.startOf('week').add(7, 'day');
        return highlightedDays
            .filter(d => d.isSameOrAfter(nextWeekStart))
            .sort((a, b) => a.valueOf() - b.valueOf())[0] || nextWeekStart;
    };



    const findNextAvailableDate = useCallback((date: any, groupedInterviews: any) => {
        const sortedDates = Object.keys(groupedInterviews)
            .map(key => dayjs(key))
            .sort((a, b) => a.valueOf() - b.valueOf());
        return sortedDates.find(d => d.isAfter(date, 'day')) || null;
    }, []);

    const findNextAvailableWeek = useCallback((date: any, groupedInterviews: any) => {
        const currentWeekEnd = date.endOf('week');
        const sortedWeeks = Object.keys(groupedInterviews)
            .map(key => dayjs(key).startOf('week'))
            .sort((a, b) => a.valueOf() - b.valueOf());
        return sortedWeeks.find(d => d.isAfter(currentWeekEnd)) || null;
    }, []);

    const handleInterviewRedirect = (submissionID: string) => {
        navigate(Paths.APPLICATIONS + '/' + submissionID + '?tab=interviews');
    };

    const formatDateKey = (date: Dayjs, viewMode: string): string => {
        const baseDate = viewMode === 'D' ? date : date.startOf('week');
        return baseDate.format('YYYY-MM-DD');
    };

    const findNextSlot = (currentDate: Dayjs, groupedInterviews: any, viewMode: string) => {
        const nextDate = viewMode === 'D'
            ? findNextAvailableDate(currentDate, groupedInterviews)
            : findNextAvailableWeek(currentDate, groupedInterviews);

        return nextDate ? formatDateKey(nextDate, viewMode) : null;
    };

    const getInterviewOpacity = (isPast: boolean): number => {
        return isPast ? 0.5 : 1;
    };

    const getCurrentAndNextKeys = (currentDate: Dayjs, groupedInterviews: any, viewMode: string) => {
        const currentKey = formatDateKey(currentDate, viewMode);
        const nextKey = findNextSlot(currentDate, groupedInterviews, viewMode);

        return { currentKey, nextKey };
    };

    const parseDuration = (duration: string) => {
        const hoursMatch = duration.match(/(\d+)h/);
        const minutesMatch = duration.match(/(\d+)m/);

        const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
        const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

        return { hours, minutes };
    };


    const renderInterviews = useCallback(() => {
        const groupedInterviews = groupInterviews(scheduledData);
        const { currentKey, nextKey } = getCurrentAndNextKeys(currentDate, groupedInterviews, viewMode);

        const renderGroup = (key: string, interviews: scheduledData[]) => (
            <React.Fragment key={key}>
                <List
                    sx={{
                        width: '312px',
                        '& .MuiListItem-root': {
                            paddingLeft: 0,
                            paddingRight: 0
                        }
                    }}
                >
                    {interviews.map((interview) => {
                        const finalizedAvailability = interview.currentAvailabilities.find((availability: any) => availability.isFinalized);

                        if (finalizedAvailability) {
                            const interviewDate = dayjs(finalizedAvailability.interviewDate);
                            const startTime = dayjs(finalizedAvailability.interviewTime, 'HH:mm:ss');

                            const { hours, minutes } = parseDuration(finalizedAvailability.interviewDuration);
                            const endTime = startTime.add(hours, 'hour').add(minutes, 'minute');
                            const isPastInterview = interviewDate.isBefore(dayjs(), 'day');

                            const itemKey = `${interview.id}-${finalizedAvailability.interviewDate}`;

                            return (
                                <ListItem
                                    key={itemKey}
                                    sx={{
                                        py: 0.5,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        opacity: getInterviewOpacity(isPastInterview)
                                    }}
                                >
                                    <Box sx={{ width: "38px", height: "38px", border: "1px solid #F1F1F1", borderRadius: "8px" }}>
                                        <Typography variant="body2" color="#4A57A3" sx={{ lineHeight: 1, fontFamily: "Roboto", fontWeight: 700, fontSize: 12, padding: "3px", textAlign: "center" }} >
                                            {interviewDate.format('ddd')}
                                        </Typography>
                                        <Typography variant="body2" color="#4A57A3" sx={{ fontWeight: 'bold', lineHeight: "14px", textAlign: "center", fontSize: "12px" }}>
                                            {interviewDate.format('DD')}
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        ml: 2,
                                    }}>
                                        <Typography variant="body2" fontWeight="bold">
                                            {interview.candidateName}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Clock size={12} color="#898989" style={{ marginRight: 4, flexShrink: 0, fontWeight: 600 }} />
                                            <Typography variant="body2" color="#898989" sx={{ fontFamily: "Roboto", fontWeight: 500, fontSize: "11px", lineHeight: "13px" }}>
                                                {`${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <ChevronRight size={24} color="#D2D5E9" style={{ marginLeft: 8, flexShrink: 0, cursor: 'pointer' }} onClick={() => handleInterviewRedirect(interview.submissionId)} />
                                </ListItem>
                            );
                        }
                        return null;
                    })}
                </List>
            </React.Fragment>
        );

        return (
            <>
                {groupedInterviews[currentKey] && renderGroup(currentKey, groupedInterviews[currentKey])}
                {nextKey && groupedInterviews[nextKey] && (
                    <>
                        <Divider sx={{ my: 2 }} />
                        {renderGroup(nextKey, groupedInterviews[nextKey])}
                    </>
                )}
            </>
        );
    }, [viewMode, scheduledData, groupInterviews, currentDate, findNextAvailableDate, findNextAvailableWeek]);

    return (
        <Box
            sx={{
                boxShadow: "0px 6px 12px 0px #4A57A31F",
                borderRadius: "10px",
                p: 2,
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
            }}
            data-testid="interview-sidebar"
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                    data-testid="current-date-display"
                    value={currentDate}
                    onChange={(newDate) => setCurrentDate(newDate || dayjs())}
                    slots={{
                        day: ServerDay,
                    }}
                    slotProps={{
                        day: {
                            highlightedDays,
                            minDate
                        } as any,
                        calendarHeader: {
                            isInInterviewSidebar: true,
                        } as any,
                    }}
                    sx={{
                        height: 290,
                        margin: 0,
                        '& .MuiPickersCalendarHeader-label': {
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            color: "#353535",
                            paddingLeft: "5px"
                        },
                        '& .MuiDayCalendar-weekDayLabel': {
                            color: '#9EADC4',
                        },
                        '& .MuiPickersDay-root': {
                            fontWeight: 400,
                            fontSize: "12px",
                        },
                        '& .MuiPickersDay-root.Mui-selected': {
                            backgroundColor: '#4B57A3 !important',
                        },
                        '& .MuiPickersCalendarHeader-switchViewButton': {
                            display: 'none',
                        },
                    }}
                    minDate={minDate}
                />
                <Grid
                    container
                    sx={{
                        margin: "5px 20px 0px 20px",
                        height: 'calc(81% - 460px)',
                        overflow: 'hidden'
                    }}
                >
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: "#353535" }}>
                            Interviews
                        </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: "#ECEFFF",
                                borderRadius: "4px",
                                width: "80px",
                            }}
                        >
                            <ChevronLeft
                                data-testid="previous-button"
                                size={20}
                                style={{ cursor: canGoBack ? 'pointer' : 'default', color: canGoBack ? 'inherit' : '#d3d3d3' }}
                                onClick={canGoBack ? handlePrevious : undefined}
                            />
                            <ToggleButtonGroup
                                value={viewMode}
                                exclusive
                                onChange={(e, newMode) => newMode && setViewMode(newMode)}
                                size="small"

                            >
                                <ToggleButton
                                    value="D"
                                    sx={{
                                        height: "20px",
                                        border: "none",
                                        '&.Mui-selected': {
                                            backgroundColor: '#ffffff',
                                            color: '#4A57A3',
                                            font: "Roboto",
                                            fontWeight: 700,
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0',
                                            },
                                        },
                                    }}
                                >
                                    D
                                </ToggleButton>
                                <ToggleButton
                                    value="W"
                                    sx={{
                                        height: "20px",
                                        border: "none",
                                        '&.Mui-selected': {
                                            backgroundColor: '#ffffff',
                                            color: '#4A57A3',
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0',
                                            },
                                        },
                                    }}
                                >
                                    W
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <ChevronRight
                                data-testid="next-button"
                                size={20}
                                style={{ cursor: 'pointer' }}
                                onClick={handleNext}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    height: 250,
                    marginLeft: "10px",
                    overflowY: 'auto',
                    "&::-webkit-scrollbar": {
                        width: "10px",
                        borderRadius: "10px",
                        border: "2px solid #f5f5f5",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                        borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#e0e0e0",
                        borderRadius: "10px",
                    },
                }}
                >
                    {renderInterviews()}
                </Box>
            </LocalizationProvider>
        </Box>
    )
}

export default InterviewSidebar