// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, ButtonBase } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";

// Functional Imports
import Colors from "../../utils/Colors";
import Paths from "../../routes/Paths";

// Component Imports
import FilterPopupProjects from "./FilterPopupProjects";
import ProjectList from "./ProjectList";
import Breadcrumbs from "../../common/Breadcrumbs";
import FilterTile from "../../common/FilterTile";
import Text from "../../common/Text";
import EndProjectPopup from "./EndProjectPopup";
import ChangeRatePopup from "./ChangeRatePopup";
import API from "../../api/API";
import ENUMS from "../../utils/Enums";
import Functions from "../../utils/Functions";

interface ProjectListingProps {
  isTesting?: boolean;
}

const ProjectListing: FC<ProjectListingProps> = (props) => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  const [selectedFiltersForRequest, setSelectedFiltersForRequest] =
    useState<any>([]);

  const [selectedFilter, setSelectedFilter] = useState<any>(
    ENUMS.PROJECT_FILTERS.PROJECT_NAME
  );

  const [searchText, setSearchText] = useState("");

  const [dataLoading, setDataLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // List Related
  const [projectsList, setProjectsList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [updateDataAgain, setUpdateDataAgain] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState<any>();
  const [projectCount, setProjectCount] = useState(0);

  // Filter Related
  const [projectNameData, setProjectNameData] = useState([]);
  const [projectNameFilters, setProjectNameFilters] = useState<any>([]);

  const [employeeNameData, setEmployeeNameData] = useState([]);
  const [employeeNameFilters, setEmployeeNameFilters] = useState<any>([]);

  const [startDateData, setStartDateData] = useState([]);
  const [startDateFilters, setStartDateFilters] = useState<any>([]);

  const [endDateData, setEndDateData] = useState([]);
  const [endDateFilters, setEndDateFilters] = useState<any>([]);

  const [statusData, setStatusData] = useState([]);
  const [statusFilters, setStatusFilters] = useState<any>([]);

  const [accountManagerData, setAccountManagerData] = useState([]);
  const [accountManagerFilters, setAccountManagerFilters] = useState<any>([]);

  useEffect(() => {
    if (props.isTesting) {
      handleClick({});
      removeFilter("");
      onClearAllFilters();
      onApply();
      removeFilter(ENUMS.PROJECT_FILTERS.PROJECT_NAME);
      removeFilter(ENUMS.PROJECT_FILTERS.EMPLOYEE_NAME);
      removeFilter(ENUMS.PROJECT_FILTERS.START_DATE);
      removeFilter(ENUMS.PROJECT_FILTERS.END_DATE);
      removeFilter(ENUMS.PROJECT_FILTERS.STATUS);
      removeFilter(ENUMS.PROJECT_FILTERS.ACCOUNT_MANAGER);
    }
  }, []);

  useEffect(() => {
    getProjectsList();
    updateFilterKeys();
  }, [updateDataAgain, pagination]);

  const getProjectsList = () => {
    let sortOrder = 1;

    if (sortModel?.[0]?.sort === "asc") {
      sortOrder = 0;
    }

    let sortField = Functions.ValueRetrieve.getSortingKeyForProjects(
      sortModel?.[0]?.field
    );

    const payload = {
      pageIndex: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      sortColumn: sortField,
      sortOrder: sortOrder,
      projectNames: projectNameFilters,
      employeeNames: employeeNameFilters,
      startDates: startDateFilters,
      endDates: endDateFilters,
      statuses: statusFilters,
      accountManagers: accountManagerFilters,
    };

    setTableLoading(true);
    API.getProjects(payload)
      .then((response) => {
        if (response.entityList) {
          setProjectsList(response.entityList);
        } else {
          setProjectsList([]);
        }
        setTableLoading(false);
        setProjectCount(Number(response.totalRecords));
      })
      .catch((e) => {
        setTableLoading(false);
      });
  };

  const handleClick = (event: any) => {
    if (filtersOpen) {
      setAnchorEl(null);
      setFiltersOpen(false);
    } else {
      setAnchorEl(event.currentTarget);
      setFiltersOpen(true);
    }
  };

  const removeFilter = (filter: string) => {
    if (filter === ENUMS.PROJECT_FILTERS.PROJECT_NAME) {
      setProjectNameFilters([]);
    } else if (filter === ENUMS.PROJECT_FILTERS.EMPLOYEE_NAME) {
      setEmployeeNameFilters([]);
    } else if (filter === ENUMS.PROJECT_FILTERS.START_DATE) {
      setStartDateFilters([]);
    } else if (filter === ENUMS.PROJECT_FILTERS.END_DATE) {
      setEndDateFilters([]);
    } else if (filter === ENUMS.PROJECT_FILTERS.STATUS) {
      setStatusFilters([]);
    } else if (filter === ENUMS.PROJECT_FILTERS.ACCOUNT_MANAGER) {
      setAccountManagerFilters([]);
    }
    setUpdateDataAgain(!updateDataAgain);
  };

  const onResetAll = () => {
    setProjectNameFilters([]);
    setEmployeeNameFilters([]);
    setStartDateFilters([]);
    setEndDateFilters([]);
    setStatusFilters([]);
    setAccountManagerFilters([]);
  };

  const onClearAllFilters = () => {
    onResetAll();
    setUpdateDataAgain(!updateDataAgain);
  };

  const onApply = () => {
    setFiltersOpen(false);
    setUpdateDataAgain(!updateDataAgain);
  };

  const updateFilterKeys = () => {
    let selectedFiltersForRequestPass = [];

    if (projectNameFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.PROJECT_FILTERS.PROJECT_NAME);
    }
    if (employeeNameFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.PROJECT_FILTERS.EMPLOYEE_NAME);
    }
    if (startDateFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.PROJECT_FILTERS.START_DATE);
    }
    if (endDateFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.PROJECT_FILTERS.END_DATE);
    }
    if (statusFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.PROJECT_FILTERS.STATUS);
    }
    if (accountManagerFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.PROJECT_FILTERS.ACCOUNT_MANAGER);
    }

    if (selectedFiltersForRequestPass.length > 0) {
      setSelectedFiltersForRequest([...selectedFiltersForRequestPass]);
    } else {
      setSelectedFiltersForRequest([]);
    }
  };

  return (
    <Box
      data-testid="project-listing-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          pt: 2,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
            mt: 1,
            mb: 0.5,
          }}
          path={[
            { path: Paths.APP + Paths.APPLICATIONS, name: "Home" },
            { name: "Projects" },
          ]}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterPopupProjects
            isExpanded={filtersOpen}
            setIsExpanded={setFiltersOpen}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            searchText={searchText}
            setSearchText={setSearchText}
            dataLoading={dataLoading}
            setDataLoading={setDataLoading}
            onApply={onApply}
            anchorEl={anchorEl}
            onResetAll={onResetAll}
            // Filters
            projectNameData={projectNameData}
            setProjectNameData={setProjectNameData}
            projectNameFilters={projectNameFilters}
            setProjectNameFilters={setProjectNameFilters}
            employeeNameData={employeeNameData}
            setEmployeeNameData={setEmployeeNameData}
            employeeNameFilters={employeeNameFilters}
            setEmployeeNameFilters={setEmployeeNameFilters}
            startDateData={startDateData}
            setStartDateData={setStartDateData}
            startDateFilters={startDateFilters}
            setStartDateFilters={setStartDateFilters}
            endDateData={endDateData}
            setEndDateData={setEndDateData}
            endDateFilters={endDateFilters}
            setEndDateFilters={setEndDateFilters}
            statusData={statusData}
            setStatusData={setStatusData}
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
            accountManagerData={accountManagerData}
            setAccountManagerData={setAccountManagerData}
            accountManagerFilters={accountManagerFilters}
            setAccountManagerFilters={setAccountManagerFilters}
          />
          <ButtonBase
            data-testid="filter-toggle-btn"
            onClick={(ev) => handleClick(ev)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              py: 0.5,
              mr: 2,
            }}
          >
            <Text fontWeight={700} fontSize={16}>
              Filters
            </Text>
            <FaAngleDown
              size={18}
              style={{
                marginTop: 2,
              }}
            />
          </ButtonBase>
          {selectedFiltersForRequest.map((item: any) => (
            <FilterTile
              key={item}
              title={item}
              onClick={() => removeFilter(item)}
            />
          ))}
          {selectedFiltersForRequest.length > 0 && (
            <ButtonBase
              onClick={onClearAllFilters}
              sx={{
                p: 0.5,
              }}
              data-testid="clear-all-filters-btn"
            >
              <Text fontWeight={700} fontSize={16}>
                Clear All Filters
              </Text>
            </ButtonBase>
          )}
        </Box>
      </Box>
      <ProjectList
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
        sortModel={sortModel}
        setSortModel={setSortModel}
        setPagination={setPagination}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        tableData={projectsList}
        tableLoading={tableLoading}
        totalEntries={projectCount}
      />
      <EndProjectPopup
        open={false}
        setOpen={() => {}}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
      <ChangeRatePopup
        open={false}
        setOpen={() => {}}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
    </Box>
  );
};

export default ProjectListing;
