// React Imports
import { FC } from "react";

// UI Imports
import { ButtonBase, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../Text";

interface ButtonOutlinedProps {
  sx?: SxProps;
  title?: string;
  testId?: string;
  onClick?:any;
}

const ButtonOutlined: FC<ButtonOutlinedProps> = (props) => {
  return (
    <ButtonBase
    onClick={props.onClick}
      data-testid={props.testId}
      sx={{
        padding: "8px 16px",
        width: "auto",
        borderRadius: 1,
        border: "1px solid " + Colors.Blue7,
        ...props.sx,
      }}
    >
      <Text fontSize={16} fontWeight={500} color={Colors.Blue7} style={{ lineHeight: "20px"}}>
        {props.title}
      </Text>
    </ButtonBase>
  );
};

export default ButtonOutlined;
