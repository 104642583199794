// React Imports
import { FC } from "react";

// UI Imports
import { ButtonBase, SxProps } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";

// Functional Imports

// Component Imports
import Text from "../Text";

interface FilterButtonProps {
  testId?: string;
  onClick?: any;
  sx?: SxProps;
}

const FilterButton: FC<FilterButtonProps> = (props) => {
  return (
    <ButtonBase
      data-testid={props.testId}
      onClick={props.onClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        py: 0.5,
        mr: 2,
        ...props.sx,
      }}
    >
      <Text fontWeight={700} fontSize={16}>
        Filters
      </Text>
      <FaAngleDown
        size={18}
        style={{
          marginTop: 2,
        }}
      />
    </ButtonBase>
  );
};

export default FilterButton;
