// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, IconButton, Popover } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { BsThreeDotsVertical } from "react-icons/bs";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import HoverActionUsers from "./HoverActionUsers";
import API from "../../api/API";

interface ActionTileUsersProps extends Partial<GridRenderCellParams> {
  updateDataAgain?: boolean;
  setUpdateDataAgain?: any;

  isTesting?: boolean;
}

const ActionTileUsers: FC<ActionTileUsersProps> = (props) => {
  const [hoverActionsEnabled, setHoverActionsEnabled] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (props.isTesting) {
      const param: any = {};
      handleClick(param);
      handleClickActiveInactive()
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (hoverActionsEnabled) {
      setAnchorEl(null);
      setHoverActionsEnabled(false);
    } else {
      setAnchorEl(event.currentTarget);
      setHoverActionsEnabled(true);
    }
  };

  const id = hoverActionsEnabled ? "simple-popover" : undefined;

  const handleClickActiveInactive = () => {
    let newStatus = "Active";

    if (props.row?.status === "Active") {
      newStatus = "Inactive";
    }

    API.updateUserStatus(props.row?.id, newStatus)
      .then((response) => {
        setHoverActionsEnabled(false);
        props.setUpdateDataAgain(!props.updateDataAgain);
      })
      .catch((e) => {});
  };

  return (
    <Box
      data-testid="action-tile-users"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0,
        position: "relative",
      }}
    >
      <IconButton
        sx={{
          borderRadius: 2,
          backgroundColor: hoverActionsEnabled ? Colors.Grey12 : null,
        }}
        data-testid="menu-btn"
        onClick={(ev) => handleClick(ev)}
      >
        <BsThreeDotsVertical size={20} color={Colors.Black1} />
      </IconButton>
      <Popover
        id={id}
        open={hoverActionsEnabled}
        anchorEl={anchorEl}
        onClose={() => setHoverActionsEnabled(false)}
        anchorOrigin={{
          vertical: 40,
          horizontal: 10,
        }}
      >
        <HoverActionUsers
          id={props.row?.id}
          status={props.row?.status}
          onClickActiveInactive={handleClickActiveInactive}
        />
      </Popover>
    </Box>
  );
};

export default ActionTileUsers;
