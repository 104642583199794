// React Imports
import React, { FC, useEffect } from "react";

// UI Imports
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Box, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import ActionTileApplication from "./ActionTileApplication";
import StatusChip from "./StatusChip";
import Loader from "../../common/Loader";
import Text from "../../common/Text";
import Paths from "../../routes/Paths";
import { Link } from "react-router-dom";

interface ApplicationHierarchyListProps {
  disableVirtualization?: boolean;
  sx?: SxProps;
  tableData?: any;
  tableLoading?: boolean;
  pageIndex: number;
  pageSize: number;
  setPagination?: any;
  totalEntries?: number;
  sortModel?: any;
  setUpdateDataAgain?: any;
  updateDataAgain?: boolean;
  setSortModel?: any;
  onShare?: any;
  onHire?: any;
  onReject?: any;
  onResumeOpen?: any;
  onScheduleInterviewOpen?: any;
  hideFooter?: boolean;

  testId?: string;
  isTesting?: boolean;
}

const ApplicationHierarchyList: FC<ApplicationHierarchyListProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      renderEmptyRow();
      renderEmptyRow();
      handlePaginationModelChange({});
      handleSortModelChange({});
    }
  }, []);

  const applicationListColumns = [
    {
      field: "actions",
      headerName: "Actions",
      ...headerText,
      renderCell: (params: GridRenderCellParams) => (
        <ActionTileApplication
          {...params}
          updateDataAgain={props.updateDataAgain}
          setUpdateDataAgain={props.setUpdateDataAgain}
          onShare={props.onShare}
          onHire={props.onHire}
          onReject={props.onReject}
          onResumeOpen={props.onResumeOpen}
          onScheduleInterviewOpen={props.onScheduleInterviewOpen}
        />
      ),
      width: 180,
      ...commonColumnProps,
      sortable: false,
    },
    ...columns,
  ];

  const handlePaginationModelChange = (ev: any) => {
    props.setPagination({
      pageIndex: ev.page,
      pageSize: ev.pageSize,
    });
  };

  const handleSortModelChange = (ev: any) => {
    props.setUpdateDataAgain(!props.updateDataAgain);
    props.setSortModel(ev);
  };

  return (
    <DataGrid
      loading={props.tableLoading}
      disableVirtualization={props.disableVirtualization}
      data-testid={props.testId}
      slots={{
        loadingOverlay: renderLoader,
        noRowsOverlay: renderEmptyRow,
      }}
      sx={{
        backgroundColor: Colors.White,
        width: "calc(100vw - 140px)",
        height: "calc(100vh - 200px)",
        marginTop: 1.5,
        ml: 4,
        ...props.sx,
      }}
      pageSizeOptions={[10, 20, 50, 100]}
      paginationModel={{
        page: props.pageIndex,
        pageSize: props.pageSize,
      }}
      paginationMode="server"
      onPaginationModelChange={handlePaginationModelChange}
      rows={props.tableData}
      getRowId={(row) => row.id}
      columns={applicationListColumns}
      rowCount={props.totalEntries}
      hideFooter={props.hideFooter}
      rowSelection={false}
      sortModel={props.sortModel}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
    />
  );
};

export default ApplicationHierarchyList;

const headerText = {
  renderHeader: (params: GridColumnHeaderParams) => {
    return (
      <Text fontSize={16} fontWeight={700}>
        {params.colDef?.headerName}
      </Text>
    );
  },
};

const cellRender = {
  renderCell: (params: GridRenderCellParams) => {
    return <Text>{params.row?.[params.field]}</Text>;
  },
};

const commonColumnProps = {
  disableColumnMenu: true,
  sortable: false,
};

const columns: GridColDef[] = [
  {
    field: "candidateName",
    headerName: "Name",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Link to={Paths.APPLICATIONS + "/" + params.row?.id}>
          <Text color={Colors.Blue1}>{params.row?.candidateName}</Text>
        </Link>
      );
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "location",
    headerName: "Location",
    ...headerText,
    ...cellRender,
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "status",
    headerName: "Status",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return <StatusChip type={params.row?.resumeVLNStatus} />;
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "experience",
    headerName: "Experience",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Text>
          {params.row?.experience != "N/A"
            ? params.row?.experience + " Years"
            : ""}{" "}
        </Text>
      );
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
];
const renderLoader = () => <Loader />;
const renderEmptyRow = () => (
  <Box
    sx={{
      mt: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Text>No data found</Text>
  </Box>
);
