enum FILTERS {
  NAME = "Name",
  LOCATION = "Location",
  STATUS = "Status",
  JOB_ID = "Job ID",
  JOB_TITLE = "Job Title",
  EXPERIENCE = "Experience",
}

enum JOB_FILTERS {
  JOB_TITLE = "Job Title",
  JOB_LOCATION = "Job Location",
  EMPLOYMENT_TYPE = "Employment Type",
  STATUS = "Status",
  HIRING_MANAGER = "Hiring Manager",
  NO_OF_APPLICATION = "No.of Applications",
  JOB_POSTED_DATE = "Job Posted Date",
}

enum USER_FILTERS {
  FIRST_NAME = "First Name",
  LAST_NAME = "Last Name",
  USERNAME = "Username",
  EMAIL_ID = "Email ID",
  STATUS = "Status",
  ROLE = "Role",
}

enum APPLICATION_STATUS_TYPES {
  REJECTED = "Rejected",
  HIRED = "Hired",
  INTERVIEW_REQUESTED = "Interview Requested",
  PENDING_REVIEW = "Pending Review",
  INTERVIEWED = "Interviewed",
  INTERVIEW_SCHEDULED = "Interview Scheduled",
}

enum JOB_STATUS_TYPES {
  IN_REVIEW = "In Review",
  INACTIVE = "Inactive",
  ACTIVE = "Active",
}

enum PROJECT_STATUS_TYPES {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  PENDING = "Pending",
}

enum CONTACT_US_TYPES {
  CREATE_PASSWORD_EXPIRED = "cp",
  RESET_PASSWORD_EXPIRED = "rp",
  NONE = "n",
}

enum DASHBOARD_ACTION_TYPES {
  JOBS = "JOBS",
  APPLICATIONS = "APPLICATIONS",
}

enum PROJECT_FILTERS {
  PROJECT_NAME = "Project Name",
  EMPLOYEE_NAME = "Employee Name",
  START_DATE = "Start Date",
  END_DATE = "End Date",
  STATUS = "Status",
  ACCOUNT_MANAGER = "Account Manager",
}

enum PROJECT_TAB_OPTIONS {
  PROJECT_DETAILS = "Project Details",
  TIMESHEET = "Timesheet",
  INVOICES = "Invoices",
  DOCUMENTS = "Documents",
  REQUEST_SUBMISSIONS = "Request Submissions",
}

const ENUMS = {
  FILTERS,
  JOB_FILTERS,
  USER_FILTERS,
  APPLICATION_STATUS_TYPES,
  JOB_STATUS_TYPES,
  CONTACT_US_TYPES,
  PROJECT_STATUS_TYPES,
  DASHBOARD_ACTION_TYPES,
  PROJECT_FILTERS,
  PROJECT_TAB_OPTIONS,
};

export default ENUMS;
