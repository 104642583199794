// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Box, Modal } from "@mui/material";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

// Functional Imports

// Component Imports

interface ResumeDocPopupProps {
  open: boolean;
  setOpen: any;
  documentLink?: any;
  documentName?: string;

  isTesting?: boolean;
}

const ResumeDocPopup: FC<ResumeDocPopupProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      handleClose();
    }
  }, []);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      sx={{
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        data-testid="resume-popup"
        sx={{
          border: "none",
          width: "60vw",
          height: "85vh",
        }}
      >
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          style={{
            width: "100%",
            height: "100%",
          }}
          documents={[
            {
              fileType: "docx",
              fileName: props.documentName,
              uri: props.documentLink,
            },
          ]}
        />
      </Box>
    </Modal>
  );
};

export default ResumeDocPopup;
