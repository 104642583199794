// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, IconButton, Modal, Paper, TextareaAutosize } from "@mui/material";
import { IoIosClose } from "react-icons/io";

// Functional Imports
import { date, number, object, string } from "yup";
import { useFormik } from "formik";
import API from "../../api/API";
import moment from "moment";
import Colors from "../../utils/Colors";
import Toasts from "../../utils/Toasts";
import STRINGS from "../../utils/Strings";
import Functions from "../../utils/Functions";

// Component Imports
import LabelRadioSelect from "../../common/LabelRadioSelect";
import LabelInput from "../../common/LabelInput";
import DateSelect from "../../common/DateSelect";
import Text from "../../common/Text";
import ButtonFilled from "../../common/ButtonFilled";

interface HirePopupProps {
  open: boolean;
  setOpen: any;
  applicationDetails?: any;
  updateDataAgain?: boolean;
  setUpdateDataAgain?: any;

  isTesting?: boolean;
}

const HirePopup: FC<HirePopupProps> = (props) => {
  const [location, setLocation] = useState("");

  const formik = useFormik({
    initialValues: {
      projectStartDate: null,
      closeJob: "",
      noOfPosition: "",
      comments: "",
    },
    enableReinitialize: true,
    validationSchema: object({
      closeJob: string().required("This field is required"),
      noOfPosition: number()
        .when("closeJob", {
          is: (closeJob: any) => closeJob === "No",
          then: (schema) => schema.required("This field is required"),
        })
        .min(0, "No of position cannot be lower than 0"),
      comments: string()
        .required("This field is required")
        .max(1000, "Comment cannot be greater than 1000 characters."),
      projectStartDate: string()
        .required("This field is required")
        .test(
          "projectStartDate",
          "Enter a valid date between 1900 and 2100",
          Functions.DateFunctions.inputDateValidation
        ),
    }),
    onSubmit: (values) => {
      onConfirm(values);
    },
  });

  useEffect(() => {
    if (props.isTesting) {
      const initTestValues: any = {
        projectStartDate: moment(),
        closeJob: "No",
        noOfPosition: "1",
        comments: "Comments",
      };
      formik.setValues(initTestValues).then((response) => {
        formik.handleSubmit();
      });
      getJobLocation();
    }
  }, []);

  useEffect(() => {
    if (props.applicationDetails?.jobId) {
      getJobLocation();
    }
  }, [props.applicationDetails?.jobId]);

  const getJobLocation = () => {
    setLocation("");
    API.getJobByStafflineId(props.applicationDetails?.jobId).then(
      (response) => {
        let locationPass = Functions.ValueRetrieve.getLocation(
          response.entity?.countryCode,
          response.entity?.stateCode,
          response.entity?.city
        );
        
        setLocation(locationPass);
      }
    );
  };

  const onConfirm = (values: any) => {
    const payload = {
      submissionId: props.applicationDetails?.id,
      comment: values.comments,
      projectStartDate: moment(values.projectStartDate).toISOString(),
      isCloseJob: values.closeJob === "Yes",
      noOfPosition: Number(values.noOfPosition),
    };

    API.hireCandidate(payload)
      .then((response) => {
        Toasts.success("The application has been successfully hired.");
        formik.resetForm();
        props.setOpen(false);
        props.setUpdateDataAgain(!props.updateDataAgain);
      })
      .catch((e) => {});
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
        formik.resetForm();
      }}
      sx={{
        display: "flex",
        border: "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        data-testid="hire-popup"
        sx={{
          p: 4,
          border: "none",
          width: 840,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fontSize={20} fontWeight={700}>
            {STRINGS.HIRE_POPUP.HIRE}
          </Text>
          <IconButton
            data-testid="close-btn"
            sx={{
              mr: -1.25,
            }}
            onClick={() => {
              formik.resetForm();
              props.setOpen(false);
            }}
          >
            <IoIosClose />
          </IconButton>
        </Box>

        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LabelInput
            label={STRINGS.HIRE_POPUP.APPLICANT_NAME}
            textFieldProps={{
              variant: "standard",
              sx: {
                width: 360,
                mt: 1.5,
              },
              inputProps: {
                style: {
                  fontWeight: 700,
                },
                sx: {
                  backgroundColor: Colors.White,
                },
                disabled: true,
              },
              value: props.applicationDetails?.candidateName,
            }}
          />
          <DateSelect
            label={STRINGS.HIRE_POPUP.PROJECT_START_DATE}
            required
            value={formik.values.projectStartDate}
            onChange={(ev) => {
              formik.setFieldValue("projectStartDate", ev);
            }}
            textFieldProps={{
              inputProps: {
                "data-testid": "project-start-date-input",
                style: {
                  fontWeight: 700,
                  backgroundColor: Colors.Grey15,
                },
              },
              sx: {
                width: 360,
                mt: 1,
              },
              id: "projectStartDate",
              name: "projectStartDate",
              onBlur: formik.handleBlur,
              error: Functions.Sonar.booleanAnd(
                formik.touched?.projectStartDate,
                Boolean(formik.errors?.projectStartDate)
              ),
              helperText: Functions.Sonar.booleanAndString(
                formik.touched?.projectStartDate,
                formik.errors?.projectStartDate
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LabelInput
            label={STRINGS.HIRE_POPUP.HIRING_MANAGER}
            textFieldProps={{
              variant: "standard",
              sx: {
                width: 360,
                mt: 1.5,
              },
              inputProps: {
                style: {
                  fontWeight: 700,
                },
                sx: {
                  backgroundColor: Colors.White,
                },
                readonly: true,
              },
              value:
                props.applicationDetails?.recruiterFirstName +
                " " +
                props.applicationDetails?.recruiterLastName,
            }}
          />
          <LabelInput
            label={STRINGS.HIRE_POPUP.LOCATION}
            textFieldProps={{
              variant: "standard",
              sx: {
                width: 360,
                mt: 1.5,
              },
              inputProps: {
                style: {
                  fontWeight: 700,
                },
                sx: {
                  backgroundColor: Colors.White,
                },
                readonly: true,
              },
              value: location,
            }}
          />
        </Box>

        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LabelRadioSelect
            label={STRINGS.HIRE_POPUP.DO_YOU_WISH_TO_CLOSE_THIS_JOB}
            required={true}
            sx={{
              width: 360,
              mt: 1,
            }}
            options={[STRINGS.HIRE_POPUP.YES, STRINGS.HIRE_POPUP.NO]}
            value={formik.values.closeJob}
            onChange={(ev: any) => {
              formik.setFieldValue("closeJob", ev);
            }}
            error={Functions.Sonar.booleanAnd(
              formik.touched?.closeJob,
              Boolean(formik.errors?.closeJob)
            )}
            helperText={Functions.Sonar.booleanAndString(
              formik.touched?.closeJob,
              formik.errors?.closeJob
            )}
          />
          {formik.values.closeJob === "No" && (
            <LabelInput
              label={STRINGS.HIRE_POPUP.HOW_MANY_POSITIONS_ARE_REMAINING}
              required={true}
              textFieldProps={{
                type: "number",
                inputProps: {
                  "data-testid": "no-of-positions-input",
                  style: {
                    fontWeight: 700,
                    backgroundColor: Colors.Grey15,
                  },
                },
                sx: {
                  width: 360,
                  mt: 1.5,
                },
                id: "noOfPosition",
                name: "noOfPosition",
                value: formik.values.noOfPosition,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                error: Functions.Sonar.booleanAnd(
                  formik.touched?.noOfPosition,
                  Boolean(formik.errors?.noOfPosition)
                ),
                helperText: Functions.Sonar.booleanAndString(
                  formik.touched?.noOfPosition,
                  formik.errors?.noOfPosition
                ),
              }}
            />
          )}
        </Box>

        <LabelInput
          label="Comments"
          required
          sx={{
            mt: 1,
          }}
          textFieldProps={{
            multiline: true,
            InputProps: {
              sx: {
                padding: 0,
              },
            },
            rows: 3,
            inputProps: {
              "data-testid": "comments-input",
              style: {
                backgroundColor: Colors.Grey15,
                fontWeight: 400,
                padding: 8,
              },
            },
            id: "comments",
            name: "comments",
            value: formik.values.comments,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            error: Functions.Sonar.booleanAnd(
              formik.touched?.comments,
              Boolean(formik.errors?.comments)
            ),
            helperText: Functions.Sonar.booleanAndString(
              formik.touched?.comments,
              formik.errors?.comments
            ),
          }}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 3,
          }}
        >
          <ButtonFilled
            title={STRINGS.HIRE_POPUP.HIRE}
            testId="confirm-btn"
            onClick={() => {
              formik.handleSubmit();
            }}
          />
        </Box>
      </Paper>
    </Modal>
  );
};

export default HirePopup;
