// React Imports
import { FC } from "react";

// UI Imports
import {
  Box,
  SxProps,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import { IoIosInformationCircleOutline } from "react-icons/io";

// Functional Imports
import Colors from "../../utils/Colors";
import Text from "../../common/Text";
import STRINGS from "../../utils/Strings";

// Component Imports

interface ReplyEmailInputProps {
  sx?: SxProps;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  testId?: string;
}

const ReplyEmailInput: FC<ReplyEmailInputProps> = (props) => {
  return (
    <Box
      sx={{
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.5,
          alignItems: "center",
        }}
      >
        <Text color={Colors.Grey9}>Reply to Email ID</Text>
        <Tooltip
          placement="right"
          arrow
          title={STRINGS.SHARE_POPUP.REPLIES_WILL_BE_SENT_TO_THIS_EMAIL_ADDRESS}
        >
          <Box
            sx={{
              width: 20,
              height: 20,
            }}
          >
            <IoIosInformationCircleOutline />
          </Box>
        </Tooltip>
      </Box>
      <TextField
        inputProps={{
          "data-testid": props.testId,
          style: {
            backgroundColor: Colors.Grey15,
          },
        }}
        FormHelperTextProps={{
          sx: {
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: 400,
            backgroundColor: Colors.White,
          },
        }}
        variant="outlined"
        size="small"
        placeholder={props.placeholder}
        sx={{
          input: {
            border: "none",
          },
          fontFamily: "Roboto",
          fontSize: 16,
          width: "100%",
          marginTop: 1,
          "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid " + Colors.Grey19,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid " + Colors.Grey19,
          },
          "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid " + Colors.DarkBlue4,
          },
        }}
        {...props.textFieldProps}
      />
    </Box>
  );
};

export default ReplyEmailInput;
