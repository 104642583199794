// React Imports
import { FC, useEffect } from "react";

// UI Imports
import {
  Box,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { IoMdSearch } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";

// Functional Imports
import { Oval } from "react-loader-spinner";
import API from "../../api/API";
import ENUMS from "../../utils/Enums";
import Colors from "../../utils/Colors";

// Component Imports
import ButtonOutlined from "../../common/ButtonOutlined";
import CheckboxLabel from "../../common/CheckboxLabel";
import ButtonFilled from "../../common/ButtonFilled";
import FilterName from "../../common/FilterName";
import Text from "../../common/Text";

interface FilterPopupProjectsProps {
  isExpanded: boolean;
  setIsExpanded?: any;

  selectedFilter?: any;
  setSelectedFilter?: any;
  searchText?: any;
  setSearchText?: any;
  dataLoading?: any;
  setDataLoading?: any;
  onResetAll?: any;
  onApply?: any;
  anchorEl?: any;

  projectNameData?: any;
  setProjectNameData?: any;
  projectNameFilters?: any;
  setProjectNameFilters?: any;
  employeeNameData?: any;
  setEmployeeNameData?: any;
  employeeNameFilters?: any;
  setEmployeeNameFilters?: any;
  startDateData?: any;
  setStartDateData?: any;
  startDateFilters?: any;
  setStartDateFilters?: any;
  endDateData?: any;
  setEndDateData?: any;
  endDateFilters?: any;
  setEndDateFilters?: any;
  statusData?: any;
  setStatusData?: any;
  statusFilters?: any;
  setStatusFilters?: any;
  accountManagerData?: any;
  setAccountManagerData?: any;
  accountManagerFilters?: any;
  setAccountManagerFilters?: any;

  isTesting?: boolean;
}

const FilterPopupProjects: FC<FilterPopupProjectsProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      resetFilterForType();
      handleClose();
    }
  }, []);

  useEffect(() => {
    let projectNameSearch = undefined;
    let employeeNameSearch = undefined;
    let startDateSearch = undefined;
    let endDateSearch = undefined;
    let statusSearch = undefined;
    let accountManagerSearch = undefined;

    if (
      props.selectedFilter === ENUMS.PROJECT_FILTERS.PROJECT_NAME &&
      props.searchText !== ""
    ) {
      projectNameSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.PROJECT_FILTERS.EMPLOYEE_NAME &&
      props.searchText !== ""
    ) {
      employeeNameSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.PROJECT_FILTERS.START_DATE &&
      props.searchText !== ""
    ) {
      startDateSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.PROJECT_FILTERS.END_DATE &&
      props.searchText !== ""
    ) {
      endDateSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.PROJECT_FILTERS.STATUS &&
      props.searchText !== ""
    ) {
      statusSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.PROJECT_FILTERS.ACCOUNT_MANAGER &&
      props.searchText !== ""
    ) {
      accountManagerSearch = props.searchText;
    }

    props.setDataLoading(true);

    let payload = {
      ProjectNameSearch: projectNameSearch,
      EmployeeNameSearch: employeeNameSearch,
      StartDateSearch: startDateSearch,
      EndDateSearch: endDateSearch,
      StatusSearch: statusSearch,
      AccountManagerSearch: accountManagerSearch,
    };

    API.getFiltersForProjects(payload)
      .then((response) => {
        props.setDataLoading(false);
        props.setProjectNameData(response.entity?.projectNameFilters);
        props.setEmployeeNameData(response.entity?.employeeNameFilters);
        props.setStartDateData(response.entity?.startDateFilters);
        props.setEndDateData(response.entity?.endDateFilters);
        props.setStatusData(response.entity?.statusFilters);
        props.setAccountManagerData(response.entity?.accountManagerFilters);
      })
      .catch((e) => {
        props.setDataLoading(false);
      });
  }, [props.searchText]);

  const resetFilterForType = () => {
    if (props.selectedFilter === ENUMS.PROJECT_FILTERS.PROJECT_NAME) {
      props.setProjectNameFilters([]);
    } else if (props.selectedFilter === ENUMS.PROJECT_FILTERS.EMPLOYEE_NAME) {
      props.setEmployeeNameFilters([]);
    } else if (props.selectedFilter === ENUMS.PROJECT_FILTERS.START_DATE) {
      props.setStartDateFilters([]);
    } else if (props.selectedFilter === ENUMS.PROJECT_FILTERS.END_DATE) {
      props.setEndDateFilters([]);
    } else if (props.selectedFilter === ENUMS.PROJECT_FILTERS.STATUS) {
      props.setStatusFilters([]);
    } else if (props.selectedFilter === ENUMS.PROJECT_FILTERS.ACCOUNT_MANAGER) {
      props.setAccountManagerFilters([]);
    }
  };

  const handleClose = () => props.setIsExpanded(false);

  const id = props.isExpanded ? "simple-popover" : undefined;

  const handleSelect = (item: any, filterArr: any, setFilterArr: any) => {
    if (filterArr.includes(item)) {
      const modifiedArr = filterArr.filter(
        (itemChild: any) => itemChild !== item
      );
      setFilterArr([...modifiedArr]);
    } else {
      setFilterArr([...filterArr, item]);
    }
  };

  return (
    <Popover
      id={id}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={props.isExpanded}
      anchorEl={props.anchorEl}
      onClose={handleClose}
    >
      <Box
        data-testid="filters-projects-popup"
        sx={{
          width: 370,
          top: 160,
          zIndex: 10,
          left: 100,
          backgroundColor: Colors.White,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            pl: 2,
          }}
        >
          <Text fontWeight={700} fontSize={16}>
            Filters
          </Text>
          <IconButton onClick={handleClose} data-testid="close-modal-btn">
            <IoClose />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: 300,
              width: "45%",
              backgroundColor: Colors.Grey11,
            }}
          >
            <FilterName
              isActive={
                props.selectedFilter === ENUMS.PROJECT_FILTERS.PROJECT_NAME
              }
              title={ENUMS.PROJECT_FILTERS.PROJECT_NAME}
              onClick={() =>
                props.setSelectedFilter(ENUMS.PROJECT_FILTERS.PROJECT_NAME)
              }
              testId="project-name-filter-btn"
            />
            <FilterName
              isActive={
                props.selectedFilter === ENUMS.PROJECT_FILTERS.EMPLOYEE_NAME
              }
              title={ENUMS.PROJECT_FILTERS.EMPLOYEE_NAME}
              onClick={() =>
                props.setSelectedFilter(ENUMS.PROJECT_FILTERS.EMPLOYEE_NAME)
              }
              testId="employee-name-filter-btn"
            />
            <FilterName
              isActive={
                props.selectedFilter === ENUMS.PROJECT_FILTERS.START_DATE
              }
              title={ENUMS.PROJECT_FILTERS.START_DATE}
              onClick={() =>
                props.setSelectedFilter(ENUMS.PROJECT_FILTERS.START_DATE)
              }
              testId="start-date-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.PROJECT_FILTERS.END_DATE}
              title={ENUMS.PROJECT_FILTERS.END_DATE}
              onClick={() =>
                props.setSelectedFilter(ENUMS.PROJECT_FILTERS.END_DATE)
              }
              testId="end-date-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.PROJECT_FILTERS.STATUS}
              title={ENUMS.PROJECT_FILTERS.STATUS}
              onClick={() =>
                props.setSelectedFilter(ENUMS.PROJECT_FILTERS.STATUS)
              }
              testId="status-filter-btn"
            />
            <FilterName
              isActive={
                props.selectedFilter === ENUMS.PROJECT_FILTERS.ACCOUNT_MANAGER
              }
              title={ENUMS.PROJECT_FILTERS.ACCOUNT_MANAGER}
              onClick={() =>
                props.setSelectedFilter(ENUMS.PROJECT_FILTERS.ACCOUNT_MANAGER)
              }
              testId="account-manager-filter-btn"
            />
          </Box>
          <Box
            sx={{
              width: "55%",
              height: 300,
              backgroundColor: Colors.White,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search"
                margin="dense"
                sx={{
                  paddingRight: 0,
                  fontFamily: "Roboto",
                  backgroundColor: Colors.White,
                  "& .MuiInputBase-root": { height: "34px" },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IoMdSearch size={20} />
                    </InputAdornment>
                  ),
                }}
                value={props.searchText}
                onChange={(ev) => {
                  props.setSearchText(ev.target.value);
                }}
              />
              <IconButton
                data-testid="search-empty-btn"
                onClick={() => resetFilterForType()}
                sx={{
                  height: 32,
                  width: 32,
                  mt: "4px",
                  borderRadius: 0.5,
                  border: "1px solid " + Colors.Grey10,
                }}
              >
                <GrPowerReset />
              </IconButton>
            </Box>
            <Box
              sx={{
                overflowY: "auto",
                height: 260,
              }}
            >
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.PROJECT_FILTERS.PROJECT_NAME &&
                props.projectNameData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="project-name-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.projectNameFilters,
                        props.setProjectNameFilters
                      )
                    }
                    checked={props.projectNameFilters.includes(item)}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.PROJECT_FILTERS.EMPLOYEE_NAME &&
                props.employeeNameData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="employee-name-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.employeeNameFilters,
                        props.setEmployeeNameFilters
                      )
                    }
                    checked={props.employeeNameFilters.includes(item)}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.PROJECT_FILTERS.START_DATE &&
                props.startDateData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="start-date-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.startDateFilters,
                        props.setStartDateFilters
                      )
                    }
                    checked={props.startDateFilters.includes(item)}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.PROJECT_FILTERS.END_DATE &&
                props.endDateData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="end-date-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.endDateFilters,
                        props.setEndDateFilters
                      )
                    }
                    checked={props.endDateFilters.includes(item)}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.PROJECT_FILTERS.STATUS &&
                props.statusData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="status-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.statusFilters,
                        props.setStatusFilters
                      )
                    }
                    checked={props.statusFilters.includes(item)}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter ===
                  ENUMS.PROJECT_FILTERS.ACCOUNT_MANAGER &&
                props.accountManagerData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="account-manager-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.accountManagerFilters,
                        props.setAccountManagerFilters
                      )
                    }
                    checked={props.accountManagerFilters.includes(item)}
                  />
                ))}
              {!props.dataLoading &&
                ((props.selectedFilter === ENUMS.PROJECT_FILTERS.PROJECT_NAME &&
                  props.projectNameData?.length === 0) ||
                  (props.selectedFilter ===
                    ENUMS.PROJECT_FILTERS.EMPLOYEE_NAME &&
                    props.employeeNameData?.length === 0) ||
                  (props.selectedFilter === ENUMS.PROJECT_FILTERS.START_DATE &&
                    props.startDateData?.length === 0) ||
                  (props.selectedFilter === ENUMS.PROJECT_FILTERS.END_DATE &&
                    props.endDateData?.length === 0) ||
                  (props.selectedFilter === ENUMS.PROJECT_FILTERS.STATUS &&
                    props.statusData?.length === 0) ||
                  (props.selectedFilter ===
                    ENUMS.PROJECT_FILTERS.ACCOUNT_MANAGER &&
                    props.accountManagerData?.length === 0)) && (
                  <Box
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      mt: 8,
                    }}
                  >
                    <Text>No Data</Text>
                  </Box>
                )}
              {props.dataLoading && (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Oval
                    width={40}
                    height={40}
                    color={Colors.Blue7}
                    secondaryColor={Colors.LightBlue1}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: 60,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            px: 2,
            gap: 2,
          }}
        >
          <ButtonOutlined title="Reset All" onClick={props.onResetAll} />
          <ButtonFilled title="Apply" onClick={props.onApply} />
        </Box>
      </Box>
    </Popover>
  );
};

export default FilterPopupProjects;
