// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import Functions from "../../utils/Functions";

// Component Imports
import QuestionTile from "./QuestionTile";

interface DetailedScreeningSubTabProps {
  detailedPreScreeningQuestions?: any;
}

const DetailedScreeningSubTab: FC<DetailedScreeningSubTabProps> = (props) => {
  const renderQuestionData = Functions.ValueRetrieve.getParsedQuestions(
    props.detailedPreScreeningQuestions?.answers
  );

  return (
    <Box data-testid="detailed-screening-sub-tab">
      {renderQuestionData.map((item: any) => (
        <QuestionTile
          key={item.question}
          question={item.question}
          options={item.options}
          checkboxOrRadio={item.checkboxOrRadio}
        />
      ))}
    </Box>
  );
};

export default DetailedScreeningSubTab;
