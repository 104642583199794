// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { ButtonBase } from "@mui/material";
import { FaPlus } from "react-icons/fa";

// Functional Imports
import Colors from "../../utils/Colors";
import Paths from "../../routes/Paths";

// Component Imports
import Text from "../../common/Text";
import { useNavigate } from "react-router-dom";

interface CreateJobButtonProps {
  setHoverCreateButton?: any;
  hoverCreateButton?: boolean;

  isTesting?: boolean;
}

const CreateJobButton: FC<CreateJobButtonProps> = (props) => {
  const navigate = useNavigate();

  const [hoverCreateButton, setHoverCreateButton] = useState(false);

  useEffect(() => {
    if (props.isTesting) {
      handleMouseEnter();
      handleMouseLeave();
      handleClick();
    }
  }, []);

  const handleMouseEnter = () => setHoverCreateButton(true);

  const handleMouseLeave = () => setHoverCreateButton(false);

  const handleClick = () => navigate(Paths.JOB + Paths.CREATE_JOB);

  return (
    <ButtonBase
      data-testid="create-job-btn"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      sx={{
        backgroundColor: Colors.Blue7,
        borderRadius: 1,
        width: hoverCreateButton ? 120 : 46,
        height: 38,
        mt: "1px",
        transition: "width 0.3s",
        ":hover": {
          backgroundColor: Colors.Blue7 + "DD",
        },
      }}
    >
      {!hoverCreateButton && <FaPlus size={14} color={Colors.White} />}
      {hoverCreateButton && (
        <Text
          style={{
            height: 25,
          }}
          fontSize={16}
          fontWeight={500}
          color={Colors.White}
        >
          Create Job
        </Text>
      )}
    </ButtonBase>
  );
};

export default CreateJobButton;
