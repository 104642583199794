// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Box, IconButton, Modal, Paper } from "@mui/material";
import { IoIosClose } from "react-icons/io";

// Functional Imports
import { useFormik } from "formik";
import { object } from "yup";
import Colors from "../../utils/Colors";

// Component Imports
import ClientPurchaseOrder from "./ClientPurchaseOrder";
import CheckboxLabel from "../../common/CheckboxLabel";
import LabelInput from "../../common/LabelInput";
import DateSelect from "../../common/DateSelect";
import Text from "../../common/Text";
import ButtonFilled from "../../common/ButtonFilled";

interface ChangeRatePopupProps {
  open: boolean;
  setOpen: any;
  updateDataAgain?: boolean;
  setUpdateDataAgain?: any;

  isTesting?: boolean;
}

const ChangeRatePopup: FC<ChangeRatePopupProps> = (props) => {
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: object({}),
    onSubmit: (values) => { },
  });

  useEffect(() => {
    if (props.isTesting) {
      closeAndReset();
    }
  }, []);

  const closeAndReset = () => {
    props.setOpen(false);
    formik.resetForm();
  };

  return (
    <Modal
      open={props.open}
      onClose={closeAndReset}
      sx={{
        display: "flex",
        border: "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        data-testid="change-rate-popup"
        sx={{
          p: 4,
          border: "none",
          width: 840,
          height: "90vh",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fontSize={20} fontWeight={700}>
            Change Rate
          </Text>
          <IconButton
            data-testid="close-btn"
            sx={{
              mr: -1.25,
            }}
            onClick={closeAndReset}
          >
            <IoIosClose />
          </IconButton>
        </Box>

        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LabelInput
            label={"Worker Name"}
            textFieldProps={{
              variant: "standard",
              sx: {
                width: 360,
                mt: 0,
              },
              inputProps: {
                style: {
                  fontWeight: 700,
                },
                sx: {
                  backgroundColor: Colors.White,
                },
                disabled: true,
              },
              value: "Amanda Thurston",
            }}
          />
          <LabelInput
            label={"Project Name"}
            textFieldProps={{
              variant: "standard",
              sx: {
                width: 360,
                mt: 0,
              },
              inputProps: {
                style: {
                  fontWeight: 700,
                },
                sx: {
                  backgroundColor: Colors.White,
                },
                disabled: true,
              },
              value: "Project 01",
            }}
          />
        </Box>

        <Box
          sx={{
            mt: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LabelInput
            label={"Bill Rate"}
            required={true}
            textFieldProps={{
              type: "number",
              inputProps: {
                "data-testid": "no-of-positions-input",
                style: {
                  fontWeight: 700,
                  backgroundColor: Colors.Grey15,
                },
              },
              sx: {
                width: 360,
                mt: 0,
              },
            }}
          />
          <LabelInput
            label={"Current bill rate"}
            textFieldProps={{
              variant: "standard",
              sx: {
                width: 360,
                mt: 0,
              },
              inputProps: {
                style: {
                  fontWeight: 700,
                },
                sx: {
                  backgroundColor: Colors.White,
                },
                disabled: true,
              },
              value: "$80.00",
            }}
          />
        </Box>

        <Box
          sx={{
            mt: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ClientPurchaseOrder
            isUploaded
            sx={{
              width: 360,
            }}
          />
        </Box>

        <Box
          sx={{
            mt: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DateSelect
            label={"Start date"}
            required
            textFieldProps={{
              inputProps: {
                "data-testid": "project-start-date-input",
                style: {
                  fontWeight: 700,
                  backgroundColor: Colors.Grey15,
                },
              },
              sx: {
                width: 360,
                mt: 1,
              },
            }}
          />
          <Box>
            <DateSelect
              label={"End date"}
              required
              textFieldProps={{
                inputProps: {
                  "data-testid": "project-start-date-input",
                  style: {
                    fontWeight: 700,
                    backgroundColor: Colors.Grey15,
                  },
                },
                sx: {
                  width: 360,
                  mt: 1,
                },
              }}
            />
            <CheckboxLabel
              sx={{
                mt: -0.5,
                ml: -1.5,
              }}
              title={"Don’t have an end date"}
              checked={false}
              onChange={() => { }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DateSelect
            label={"Effective date"}
            required
            textFieldProps={{
              inputProps: {
                "data-testid": "project-start-date-input",
                style: {
                  fontWeight: 700,
                  backgroundColor: Colors.Grey15,
                },
              },
              sx: {
                width: 360,
                mt: 1,
              },
            }}
          />
        </Box>

        <LabelInput
          label="Comments"
          required
          sx={{
            mt: 3,
          }}
          textFieldProps={{
            multiline: true,
            InputProps: {
              sx: {
                padding: 0,
              },
            },
            rows: 3,
            inputProps: {
              "data-testid": "comments-input",
              style: {
                backgroundColor: Colors.Grey15,
                fontWeight: 400,
                padding: 8,
              },
            },
          }}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 3,
          }}
        >
          <ButtonFilled
            title={"Submit Request"}
            sx={{
              width: 180,
              height: 50,
            }}
            testId="confirm-btn"
            onClick={() => {
              formik.handleSubmit();
            }}
          />
        </Box>
      </Paper>
    </Modal>
  );
};

export default ChangeRatePopup;
