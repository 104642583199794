// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";
import Text from "../../common/Text";
import Colors from "../../utils/Colors";
import ENUMS from "../../utils/Enums";

// Functional Imports

// Component Imports

interface StatusChipProps {
  type?: string;
  testId?: string;
}

const StatusChip: FC<StatusChipProps> = (props) => {
  let backgroundColor;
  let borderColor;
  let textColor;

  if (props.type === ENUMS.PROJECT_STATUS_TYPES.PENDING) {
    backgroundColor = Colors.Grey4;
    borderColor = Colors.Grey3;
    textColor = Colors.Black1;
  } else if (props.type === ENUMS.PROJECT_STATUS_TYPES.INACTIVE) {
    borderColor = Colors.Red2;
    backgroundColor = Colors.LightRed;
    textColor = Colors.Red3;
  } else if (props.type === ENUMS.PROJECT_STATUS_TYPES.ACTIVE) {
    borderColor = Colors.Green2;
    backgroundColor = Colors.LightGreen;
    textColor = Colors.Green4;
  } 

  return (
    <Text
      testId={props.testId}
      style={{
        backgroundColor: backgroundColor,
        border: "1px solid " + borderColor,
        padding: "2px 12px 2px 12px",
        borderRadius: 16,
      }}
      color={textColor}
    >
      {props.type}
    </Text>
  );
};

export default StatusChip;
