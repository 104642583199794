// React Imports
import { FC } from "react";

// UI Imports
import { ButtonBase, SxProps } from "@mui/material";

// Functional Imports

// Component Imports
import Text from "../Text";

interface ClearAllFiltersButtonProps {
  onClick?: any;
  sx?: SxProps;
  testId?: string;
}

const ClearAllFiltersButton: FC<ClearAllFiltersButtonProps> = (props) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      sx={{
        p: 0.5,
        ...props.sx,
      }}
      data-testid={props.testId}
    >
      <Text fontWeight={700} fontSize={16}>
        Clear All Filters
      </Text>
    </ButtonBase>
  );
};

export default ClearAllFiltersButton;
