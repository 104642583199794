// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps, TextField, TextFieldProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../Text";

interface LabelInputProps {
  label: string;
  labelTextColor?: string;
  required?: boolean;
  sx?: SxProps;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  testId?: string;
  inputProps?: any;
  error?: any;
}

const LabelInput: FC<LabelInputProps> = (props) => {
  return (
    <Box
      sx={{
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.3,
        }}
      >
        <Text color={props.labelTextColor}>{props.label}</Text>
        {props.required && <Text color={Colors.Red}>*</Text>}
      </Box>
      <TextField
        InputProps={{
          ...props.inputProps,
        }}
        inputProps={{
          "data-testid": props.testId,
          style: {
            backgroundColor: Colors.Grey15,
          },
        }}
        FormHelperTextProps={{
          sx: {
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: 400,
            backgroundColor: Colors.White,
            marginLeft: 0,
          },
        }}
        helperText={props.error ? props.error.message : " "}
        variant="outlined"
        size="small"
        placeholder={props.placeholder}
        sx={{
          fontFamily: "Roboto",
          fontSize: 16,
          width: "100%",
          marginTop: 1,
        }}
        {...props.textFieldProps}
      />
    </Box>
  );
};

export default LabelInput;

LabelInput.defaultProps = {
  labelTextColor: Colors.Grey9,
};
