// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import {
  Box,
  IconButton,
  Modal,
  Paper,
} from "@mui/material";
import { IoIosClose } from "react-icons/io";

// Functional Imports

// Component Imports
import Text from "../../common/Text";
import Colors from "../../utils/Colors";
import STRINGS from "../../utils/Strings";
import { useFormik } from "formik";
import { object, string } from "yup";
import API from "../../api/API";
import Toasts from "../../utils/Toasts";
import LabelInput from "../../common/LabelInput";
import ButtonFilled from "../../common/ButtonFilled";

interface RejectPopupProps {
  open: boolean;
  setOpen: any;
  applicationDetails?: any;
  updateDataAgain?: boolean;
  setUpdateDataAgain?: any;
  isTesting?: boolean;
}

const RejectPopup: FC<RejectPopupProps> = (props) => {
  const [notesError, setNotesError] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      notes: "",
    },
    enableReinitialize: true,
    validationSchema: object({
      notes: string()
        .required("Notes are required")
        .max(1000, "Notes must be 1000 characters or less")
        .matches(/^[a-zA-Z0-9_.,!?:'\n -]*$/, "No special characters allowed"),
    }),
    onSubmit: (values) => {
      onConfirm(values);
    },
  });

  const handleNotesChange = (e: any) => {
    const { value } = e.target;
    formik.setFieldValue("notes", value);

    const specialCharPattern = /^[a-zA-Z0-9_ .,!?:'\n-]*$/;
    if (!specialCharPattern.test(value)) {
      setNotesError("No special characters allowed");
    } else {
      setNotesError(null);
    }
  };

  useEffect(() => {
    if (props.isTesting) {
      formik
        .setValues({
          notes: "Notes",
        })
        .then((response) => {
          formik.handleSubmit();
        });
    }
  }, []);

  const onConfirm = (values: any) => {
    const payload = {
      submissionId: props.applicationDetails?.id,
      comment: values.notes,
      adminGuid: "",
    };

    API.rejectCandidate(payload)
      .then((response) => {
        Toasts.success("The application has been successfully rejected.");
        props.setOpen(false);
        formik.resetForm();
        props.setUpdateDataAgain(!props.updateDataAgain);
      })
      .catch((e) => { });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        formik.resetForm();
        props.setOpen(false);
      }}
      sx={{
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        data-testid="reject-popup"
        sx={{
          p: 4,
          border: "none",
          width: 600,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fontSize={20} fontWeight={700}>
            {STRINGS.REJECT_POPUP.REJECTION_REASON}
          </Text>
          <IconButton
            data-testid="close-btn"
            sx={{ paddingRight: 0, marginRight: "-6px" }}
            onClick={() => {
              formik.resetForm();
              props.setOpen(false);
              setNotesError(null);
            }}
          >
            <IoIosClose />
          </IconButton>
        </Box>

        <LabelInput
          required
          label="Notes"
          sx={{
            mt: 1,
          }}
          textFieldProps={{
            multiline: true,
            rows: 3,
            inputProps: {
              "data-testid": "notes-input",
              style: {
                backgroundColor: Colors.White,
              },
            },
            id: "notes",
            name: "notes",
            value: formik.values.notes,
            onChange: handleNotesChange,
            onBlur: formik.handleBlur,
            error: Boolean(notesError),
            helperText: (
              <span style={{ color: '#F81919' }}>
                {notesError || formik.errors.notes}
              </span>
            ),
          }}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 3,
          }}
        >
          <ButtonFilled
            testId="reject-btn"
            title={STRINGS.REJECT_POPUP.REJECT}
            onClick={() => {
              formik.handleSubmit();
            }}
          />
        </Box>
      </Paper>
    </Modal>
  );
};

export default RejectPopup;
