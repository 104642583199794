// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import {
  Box,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { IoMdSearch } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";
import { Oval } from "react-loader-spinner";

// Functional Imports
import Colors from "../../utils/Colors";
import ENUMS from "../../utils/Enums";

// Component Imports
import ButtonOutlined from "../../common/ButtonOutlined";
import CheckboxLabel from "../../common/CheckboxLabel";
import ButtonFilled from "../../common/ButtonFilled";
import FilterName from "../../common/FilterName";
import Text from "../../common/Text";
import API from "../../api/API";

interface FilterPopupUsersProps {
  isExpanded: boolean;
  setIsExpanded?: any;

  selectedFilter?: any;
  setSelectedFilter?: any;
  searchText?: any;
  setSearchText?: any;
  dataLoading?: any;
  setDataLoading?: any;

  firstNameData?: any;
  setFirstNameData?: any;
  firstNameFilters?: any;
  setFirstNameFilters?: any;

  lastNameFilters?: any;
  setLastNameFilters?: any;
  lastNameData?: any;
  setLastNameData?: any;

  emailIdFilters?: any;
  setEmailIdFilters?: any;
  emailIdData?: any;
  setEmailIdData?: any;

  statusFilters?: any;
  setStatusFilters?: any;
  statusData?: any;
  setStatusData?: any;

  roleFilters?: any;
  setRoleFilters?: any;
  roleData?: any;
  setRoleData?: any;

  onResetAll?: any;
  onApply?: any;
  anchorEl?: any;

  isTesting?: boolean;
}

const FilterPopupUsers: FC<FilterPopupUsersProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      resetFilterForType();
      handleClose();
    }
  }, []);

  useEffect(() => {
    let firstNameSearch = undefined;
    let lastNameSearch = undefined;
    let emailSearch = undefined;
    let roleSearch = undefined;
    let statusSearch = undefined;

    if (
      props.selectedFilter === ENUMS.USER_FILTERS.FIRST_NAME &&
      props.searchText !== ""
    ) {
      firstNameSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.USER_FILTERS.LAST_NAME &&
      props.searchText !== ""
    ) {
      lastNameSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.USER_FILTERS.EMAIL_ID &&
      props.searchText !== ""
    ) {
      emailSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.USER_FILTERS.ROLE &&
      props.searchText !== ""
    ) {
      roleSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.USER_FILTERS.STATUS &&
      props.searchText !== ""
    ) {
      statusSearch = props.searchText;
    }

    let payload = {
      FirstNameSearch: firstNameSearch,
      LastNameSearch: lastNameSearch,
      StatusSearch: statusSearch,
      EmailIdSearch: emailSearch,
      RoleSearch: roleSearch,
    };

    props.setDataLoading(true);
    API.getFiltersForUsers(payload).then((response) => {
      props.setDataLoading(false);
      props.setFirstNameData(response.entity?.firstNameFilters);
      props.setLastNameData(response.entity?.lastNameFilters);
      props.setEmailIdData(response.entity?.emailIdFilters);
      props.setStatusData(response.entity?.statusFilters);
      props.setRoleData(response.entity?.roleFilters);
    });
  }, [props.searchText]);

  const resetFilterForType = () => {
    if (props.selectedFilter === ENUMS.USER_FILTERS.FIRST_NAME) {
      props.setFirstNameFilters([]);
    } else if (props.selectedFilter === ENUMS.USER_FILTERS.LAST_NAME) {
      props.setLastNameFilters([]);
    } else if (props.selectedFilter === ENUMS.USER_FILTERS.EMAIL_ID) {
      props.setEmailIdFilters([]);
    } else if (props.selectedFilter === ENUMS.USER_FILTERS.ROLE) {
      props.setRoleFilters([]);
    } else if (props.selectedFilter === ENUMS.USER_FILTERS.STATUS) {
      props.setStatusFilters([]);
    }
  };

  const id = props.isExpanded ? "simple-popover" : undefined;

  const handleClose = () => props.setIsExpanded(false);

  const handleSelect = (item: any, filterArr: any, setFilterArr: any) => {
    if (filterArr.includes(item)) {
      const modifiedArr = filterArr.filter(
        (itemChild: any) => itemChild !== item
      );
      setFilterArr([...modifiedArr]);
    } else {
      setFilterArr([...filterArr, item]);
    }
  };

  return (
    <Popover
      id={id}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={props.isExpanded}
      anchorEl={props.anchorEl}
      onClose={handleClose}
    >
      <Box
        data-testid="filters-users-popup"
        sx={{
          width: 370,
          top: 160,
          zIndex: 10,
          left: 100,
          backgroundColor: Colors.White,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            pl: 2,
          }}
        >
          <Text fontWeight={700} fontSize={16}>
            Filters
          </Text>
          <IconButton onClick={handleClose} data-testid="close-modal-btn">
            <IoClose />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: 300,
              width: "45%",
              backgroundColor: Colors.Grey11,
            }}
          >
            <FilterName
              isActive={props.selectedFilter === ENUMS.USER_FILTERS.FIRST_NAME}
              title="First Name"
              onClick={() =>
                props.setSelectedFilter(ENUMS.USER_FILTERS.FIRST_NAME)
              }
              testId="first-name-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.USER_FILTERS.LAST_NAME}
              title="Last Name"
              onClick={() =>
                props.setSelectedFilter(ENUMS.USER_FILTERS.LAST_NAME)
              }
              testId="last-name-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.USER_FILTERS.EMAIL_ID}
              title="Email ID"
              onClick={() =>
                props.setSelectedFilter(ENUMS.USER_FILTERS.EMAIL_ID)
              }
              testId="email-id-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.USER_FILTERS.STATUS}
              title="Status"
              onClick={() => props.setSelectedFilter(ENUMS.USER_FILTERS.STATUS)}
              testId="status-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.USER_FILTERS.ROLE}
              title="Role"
              onClick={() => props.setSelectedFilter(ENUMS.USER_FILTERS.ROLE)}
              testId="role-filter-btn"
            />
          </Box>
          <Box
            sx={{
              width: "55%",
              height: 300,
              backgroundColor: Colors.White,
              // border: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search"
                margin="dense"
                sx={{
                  paddingRight: 0,
                  fontFamily: "Roboto",
                  backgroundColor: Colors.White,
                  "& .MuiInputBase-root": { height: "34px" },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IoMdSearch size={20} />
                    </InputAdornment>
                  ),
                }}
                value={props.searchText}
                onChange={(ev) => {
                  props.setSearchText(ev.target.value);
                }}
              />
              <IconButton
                data-testid="search-empty-btn"
                onClick={() => resetFilterForType()}
                sx={{
                  height: 32,
                  width: 32,
                  mt: "4px",
                  borderRadius: 0.5,
                  border: "1px solid " + Colors.Grey10,
                }}
              >
                <GrPowerReset />
              </IconButton>
            </Box>
            <Box
              sx={{
                // border: 2,
                overflowY: "auto",
                height: 260,
              }}
            >
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.USER_FILTERS.FIRST_NAME &&
                props.firstNameData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="first-name-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.firstNameFilters,
                        props.setFirstNameFilters
                      )
                    }
                    checked={props.firstNameFilters.includes(String(item))}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.USER_FILTERS.LAST_NAME &&
                props.lastNameData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="last-name-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.lastNameFilters,
                        props.setLastNameFilters
                      )
                    }
                    checked={props.lastNameFilters.includes(String(item))}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.USER_FILTERS.EMAIL_ID &&
                props.emailIdData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="email-id-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.emailIdFilters,
                        props.setEmailIdFilters
                      )
                    }
                    checked={props.emailIdFilters.includes(String(item))}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.USER_FILTERS.ROLE &&
                props.roleData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="role-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.roleFilters,
                        props.setRoleFilters
                      )
                    }
                    checked={props.roleFilters.includes(String(item))}
                  />
                ))}
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.USER_FILTERS.STATUS &&
                props.statusData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    title={item}
                    checkBoxTestId="status-checkbox"
                    onChange={() =>
                      handleSelect(
                        item,
                        props.statusFilters,
                        props.setStatusFilters
                      )
                    }
                    checked={props.statusFilters.includes(String(item))}
                  />
                ))}
              {!props.dataLoading &&
                ((props.selectedFilter === ENUMS.USER_FILTERS.FIRST_NAME &&
                  props.firstNameData?.length === 0) ||
                  (props.selectedFilter === ENUMS.USER_FILTERS.LAST_NAME &&
                    props.lastNameData?.length === 0) ||
                  (props.selectedFilter === ENUMS.USER_FILTERS.EMAIL_ID &&
                    props.emailIdData?.length === 0) ||
                  (props.selectedFilter === ENUMS.USER_FILTERS.STATUS &&
                    props.statusData?.length === 0) ||
                  (props.selectedFilter === ENUMS.USER_FILTERS.ROLE &&
                    props.roleData?.length === 0)) && (
                  <Box
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      mt: 8,
                    }}
                  >
                    <Text>No Data</Text>
                  </Box>
                )}
              {props.dataLoading && (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Oval
                    width={40}
                    height={40}
                    color={Colors.Blue7}
                    secondaryColor={Colors.LightBlue1}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: 60,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            px: 2,
            gap: 2,
          }}
        >
          <ButtonOutlined title="Reset All" onClick={props.onResetAll} />
          <ButtonFilled title="Apply" onClick={props.onApply} />
        </Box>
      </Box>
    </Popover>
  );
};

export default FilterPopupUsers;
