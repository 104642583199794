// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Paper, SxProps } from "@mui/material";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoDocumentTextOutline } from "react-icons/io5";

// Functional Imports
import { useNavigate } from "react-router-dom";
import Functions from "../../utils/Functions";
import Colors from "../../utils/Colors";
import Paths from "../../routes/Paths";
import API from "../../api/API";

// Component Imports
import HoverActionTile from "../../common/HoverActionTile";

interface HoverActionDashboardProps {
  id?: string;
  docId?: string;
  docName: string;
  sx?: SxProps;
  onResumeOpen?: any;
  handlePopoverClose?: any;
  setDataLoading?: any;

  isTesting?: boolean;
}

const HoverActionDashboard: FC<HoverActionDashboardProps> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isTesting) {
      onDownload();
      onViewDetailsClick();
    }
  }, []);

  const onDownload = () => {
    props.setDataLoading(true);
    props.handlePopoverClose();

    const isDoc = props.docName?.includes("doc");

    API.downloadResume(Number(props.docId))
      .then((response) => {
        props.setDataLoading(false);

        let linkToPdf = String(response.entity);

        if (!linkToPdf.includes("https")) {
          linkToPdf = linkToPdf?.replace("http", "https");
        }

        props.onResumeOpen(linkToPdf, isDoc, props.docName);
      })
      .catch((e) => {});
  };

  const onViewDetailsClick = () =>
    navigate(Paths.APPLICATIONS + "/" + props.id);

  return (
    <Paper
      data-testid="hover-actions-dashboard-popup"
      sx={{
        backgroundColor: Colors.White,
        width: 200,
        borderRadius: 1,
        ...props.sx,
      }}
    >
      <HoverActionTile
        testId="view-details-btn"
        icon={<GiHamburgerMenu size={20} color={Colors.Blue7} />}
        title="View Details"
        onClick={onViewDetailsClick}
      />
      <HoverActionTile
        testId="view-resume-btn"
        icon={<IoDocumentTextOutline size={20} color={Colors.Blue7} />}
        title="View Resume"
        onClick={onDownload}
      />
    </Paper>
  );
};

export default HoverActionDashboard;
