// React Imports
import { CSSProperties, FC } from "react";

// UI Imports
import { Box, Checkbox, CheckboxProps, SxProps } from "@mui/material";
import Text from "../../common/Text";
import Colors from "../../utils/Colors";

// Functional Imports

// Component Imports

interface CheckboxLabelProps {
  sx?: SxProps;
  title?: string;
  onChange?: any;
  checked?: boolean;
  checkBoxTestId?: string;
  checkboxProps?: CheckboxProps;
  textStyle?: CSSProperties;
}

const CheckboxLabel: FC<CheckboxLabelProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        ...props.sx,
      }}
    >
      <Checkbox
        inputProps={{
          // @ts-ignore
          "data-testid": props.checkBoxTestId,
        }}
        checked={props.checked}
        onChange={props.onChange}
        style={{
          color: Colors.Blue7,
        }}
        {...props.checkboxProps}
      />
      <Text style={props.textStyle}>{props.title}</Text>
    </Box>
  );
};

export default CheckboxLabel;
