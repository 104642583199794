// React Imports
import { FC } from "react";

// UI Imports
import { Box, Radio, SxProps } from "@mui/material";

// Functional Imports

// Component Imports
import Text from "../Text";
import Colors from "../../utils/Colors";

interface LabelRadioProps {
  checked: boolean;
  label: string;
  sx?: SxProps;
  testId?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  textProps?: any;

  checkTestId?: string;
}

const LabelRadio: FC<LabelRadioProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.sx,
      }}
    >
      <Radio
        data-testid={props.checkTestId}
        disabled={props.disabled}
        onClick={props.onClick}
        checked={props.checked}
        sx={{
          "&, &.Mui-checked": {
            color: Colors.Blue7,
          },
        }}
      />
      <Text fontWeight={500} {...props.textProps}>
        {props.label}
      </Text>
    </Box>
  );
};

export default LabelRadio;
