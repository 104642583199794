// React Imports
import React, { FC, useState, useEffect } from "react";

// UI Imports
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Box, SxProps } from "@mui/material";
import { Link } from "react-router-dom";
import "./EditJob.css";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";
import StatusChip from "../Applications/StatusChip";
import Loader from "../../common/Loader";
import API from "../../api/API";
import Toasts from "../../utils/Toasts";
import Paths from "../../routes/Paths";
import ActionTileApplication from "../Applications/ActionTileApplication";
import HirePopup from "../Applications/HirePopup";
import RejectPopup from "../Applications/RejectPopup";
import SharePopup from "../Applications/SharePopup";
import ModalForProposeTimeSlot from "../Applications/ModalForProposeTimeSlot";
import ResumePopup from "../../common/ResumePopup";
import EmptyList from "../Applications/EmptyList";
import ResumeDocPopup from "../../common/ResumeDocPopup";
import ResumeTxtPopup from "../../common/ResumeTxtPopup";

interface JobapplicationProps {
  disableVirtualization?: boolean;
  sx?: SxProps;
  tableLoading?: boolean;
  totalEntries?: number;
  appjobid?: any;
  onShare?: any;
  onHire?: any;
  onReject?: any;

  isTesting?: boolean;
}

const Jobapplication: FC<JobapplicationProps> = (props) => {
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortModel, setSortModel] = useState<any>([]);
  const [updateDataAgain, setUpdateDataAgain] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [totalEntries, setTotalEntries] = useState(0);

  const [shareOpen, setShareOpen] = useState(false);
  const [shareDetails, setShareDetails] = useState();

  const [hireOpen, setHireOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const onShare = (row: any) => {
    setShareDetails(row);
    setShareOpen(true);
  };
  const [resumeOpen, setResumeOpen] = useState(false);
  const [resumeLink, setResumeLink] = useState("");
  const [resumeDocOpen, setResumeDocOpen] = useState(false);
  const [resumeTxtOpen, setResumeTxtOpen] = useState(false);
  const [documentName, setDocumentName] = useState("");

  const [scheduleInterviewOpen, setScheduleInterviewOpen] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);
  const [slotId, setSlotId] = useState<string[]>([]);
  const [description, setDescription] = useState<string>("");
  const [candidateName, setCandidateName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [resumeVLNStatus, setResumeVLNStatus] = useState("");
  const [submissionId, setSubmissionId] = useState("");

  useEffect(() => {
    if (props.isTesting) {
      onHire({});
      onReject({});
      onResumeOpen({}, true, "");
      onScheduleInterviewOpen(
        "Interview Requested",
        [{ isActive: true }],
        "",
        "",
        ""
      );
      onShare({});
    }
  }, []);

  const onHire = (row: any) => {
    setShareDetails(row);
    setHireOpen(true);
  };

  const onReject = (row: any) => {
    setShareDetails(row);
    setRejectOpen(true);
  };

  const onResumeOpen = (link: any, isDoc: boolean, docName: string) => {
    if (isDoc) {
      setResumeDocOpen(true);
    } else {
      if (docName.includes("txt")) {
        setResumeTxtOpen(true);
      } else {
        setResumeOpen(true);
      }
    }
    setResumeLink(link);
    setDocumentName(docName);
  };

  let jobid = props.appjobid;

  useEffect(() => {
    getJobList();
  }, [updateDataAgain, pagination]);

  const getJobList = () => {
    setTableLoading(true);

    let sortField = "";
    let sortOrder = "Descending";

    if (sortModel?.[0]?.sort === "asc") {
      sortOrder = "Ascending";
    }

    if (sortModel?.[0]?.field === "name") {
      sortField = "name";
    } else if (sortModel?.[0]?.field === "location") {
      sortField = "location";
    } else if (sortModel?.[0]?.field === "status") {
      sortField = "status";
    } else if (sortModel?.[0]?.field === "experience") {
      sortField = "experience";
    }

    const payload = {
      jobId: jobid,
      pageIndex: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      sortColumn: sortField,
      sortOrder: sortOrder,
    };

    API.getApplicationsByjobid(payload)
      .then((response) => {
        setTableData(response.entityList);
        setTableLoading(false);
        setTotalEntries(response.totalRecords);
      })
      .catch((e) => {
        Toasts.error(e);
        setTableData([]);
        setTableLoading(false);
      });
  };

  const onScheduleInterviewOpen = (
    resumeVLNStatus: string,
    currentAvailabilities: any,
    candidateName: string,
    candidateEmail: string,
    id: string
  ) => {
    setCandidateName(candidateName);
    setCandidateEmail(candidateEmail);
    setResumeVLNStatus(resumeVLNStatus);
    setSubmissionId(id);
    if (
      resumeVLNStatus === "Interview Requested" ||
      resumeVLNStatus === "Interview Scheduled"
    ) {
      const activeAvailabilities = currentAvailabilities?.filter(
        (availability: any) => availability.isActive
      );
      const slotId = currentAvailabilities?.filter(
        (availability: any) => availability.id
      );

      if (activeAvailabilities?.length > 0) {
        setAvailabilities(activeAvailabilities?.[0]?.currentAvailabilities);
        setSlotId(slotId);
        setDescription(activeAvailabilities?.[0]?.description);
      }
    }

    setScheduleInterviewOpen(true);
  };

  const applicationListColumns = [
    {
      field: "actions",
      headerName: "Actions",
      ...headerText,
      renderCell: (params: GridRenderCellParams) => (
        <ActionTileApplication
          {...params}
          updateDataAgain={updateDataAgain}
          setUpdateDataAgain={setUpdateDataAgain}
          onShare={onShare}
          onHire={onHire}
          onReject={onReject}
          onResumeOpen={onResumeOpen}
          onScheduleInterviewOpen={onScheduleInterviewOpen}
        />
      ),
      width: 250,
      ...commonColumnProps,
      sortable: false,
    },
    ...columns,
  ];

  return (
    <>
      {tableData.length === 0 && !tableLoading && (
        <EmptyList
          sx={{
            p: 4,
          }}
        />
      )}
      {(tableData.length > 0 || tableLoading) && (
        <DataGrid
          disableVirtualization={props.disableVirtualization}
          loading={tableLoading}
          data-testid={"jobapplication-list"}
          className="jobapplication-list"
          slots={{
            loadingOverlay: renderLoader,
          }}
          slotProps={{
            pagination: {
              labelRowsPerPage: "",
            },
          }}
          sx={{
            backgroundColor: Colors.White,
            minHeight: 400,
            marginTop: 1.5,
            ml: 4,
            ...props.sx,
          }}
          paginationModel={{
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          paginationMode="server"
          onPaginationModelChange={(ev) => {
            setPagination({
              pageIndex: ev.page,
              pageSize: ev.pageSize,
            });
          }}
          rows={tableData}
          getRowId={(row) => row.id}
          columns={applicationListColumns}
          rowCount={totalEntries}
          rowSelection={false}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(ev) => {
            setUpdateDataAgain(!updateDataAgain);
            setSortModel(ev);
          }}
        />
      )}
      <HirePopup
        open={hireOpen}
        setOpen={setHireOpen}
        applicationDetails={shareDetails}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
      <RejectPopup
        open={rejectOpen}
        setOpen={setRejectOpen}
        applicationDetails={shareDetails}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
      <SharePopup
        open={shareOpen}
        setOpen={setShareOpen}
        shareDetails={shareDetails}
      />
      <ResumePopup
        open={resumeOpen}
        setOpen={setResumeOpen}
        resumeLink={resumeLink}
      />
      <ResumeDocPopup
        open={resumeDocOpen}
        setOpen={setResumeDocOpen}
        documentLink={resumeLink}
        documentName={documentName}
      />
      <ResumeTxtPopup
        open={resumeTxtOpen}
        setOpen={setResumeTxtOpen}
        documentLink={resumeLink}
      />
      <ModalForProposeTimeSlot
        scheduleInterviewOpen={scheduleInterviewOpen}
        setScheduleInterviewOpen={setScheduleInterviewOpen}
        availabilities={availabilities}
        setAvailabilities={setAvailabilities}
        candidateName={candidateName}
        candidateEmail={candidateEmail}
        slotId={slotId}
        setSlotId={setSlotId}
        description={description}
        setDescription={setDescription}
        resumeVLNStatus={resumeVLNStatus}
        id={submissionId}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
    </>
  );
};

export default Jobapplication;

const headerText = {
  renderHeader: (params: GridColumnHeaderParams) => (
    <Text fontSize={16} fontWeight={700}>
      {params.colDef?.headerName}
    </Text>
  ),
};

const commonColumnProps = {
  disableColumnMenu: true,
};

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Link to={Paths.APPLICATIONS + "/" + params.row?.id}>
          <Text color={Colors.Blue1}>{params.row?.candidateName}</Text>
        </Link>
      );
    },
    width: 180,
    ...commonColumnProps,
  },
  {
    field: "location",
    headerName: "Location",
    ...headerText,
    width: 280,
    ...commonColumnProps,
  },
  {
    field: "experience",
    headerName: "Experience",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Text>
          {params.row?.experience != "N/A"
            ? params.row?.experience + " Years"
            : ""}{" "}
        </Text>
      );
    },
    width: 250,
    ...commonColumnProps,
  },
  {
    field: "status",
    headerName: "Status",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return <StatusChip type={params.row?.resumeVLNStatus} />;
    },
    width: 250,
    ...commonColumnProps,
  },
];

const renderLoader = () => <Loader />;
