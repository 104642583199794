// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import CheckboxLabel from "../../common/CheckboxLabel";
import LabelRadio from "../../common/LabelRadio";
import Text from "../../common/Text";

interface QuestionTileProps {
  question?: string;
  options?: any[];
  checkboxOrRadio?: boolean;
}

const QuestionTile: FC<QuestionTileProps> = (props) => {
  return (
    <Box
      sx={{
        border: "1px solid " + Colors.Grey20,
        width: "100%",
        p: 2,
        pb: 1,
        mb: 2,
      }}
    >
      <Text
        style={{
          marginBottom: 4,
        }}
      >
        {props.question}
      </Text>
      {props.checkboxOrRadio &&
        props.options?.map((item) => (
          <CheckboxLabel
            key={item.text}
            title={item.text}
            sx={{
              ml: -2,
              mt: -0.5,
            }}
            checked={item.selected}
            onChange={() => {}}
            checkboxProps={{
              disabled: true,
            }}
          />
        ))}
      {!props.checkboxOrRadio &&
        props.options?.map((item) => (
          <LabelRadio
            key={item.text}
            label={item.text}
            checked={item.selected}
            sx={{
              ml: -2,
              justifyContent: "flex-start",
            }}
            disabled
            textProps={{
              fontWeight: 400,
            }}
          />
        ))}
    </Box>
  );
};

export default QuestionTile;
