// React Imports
import { FC } from "react";

// UI Imports
import { SxProps, TextFieldProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import LabelInput from "../LabelInput";

interface LabelInputReadOnlyProps {
  label: string;
  value?: string;
  sx?: SxProps;
  textFieldProps?: TextFieldProps;

  testId?: string;
}

const LabelInputReadOnly: FC<LabelInputReadOnlyProps> = (props) => {
  return (
    <LabelInput
      label={props.label}
      sx={props.sx}
      textFieldProps={{
        variant: "standard",
        disabled: true,
        sx: {
          width: 320,
          mt: 0.5,
        },
        inputProps: {
          "data-testid": props.testId,
          style: {
            WebkitTextFillColor: Colors.Black1,
            color: Colors.Black1,
          },
          sx: {
            backgroundColor: Colors.White,
          },
        },
        value: props.value,
        ...props.textFieldProps,
      }}
    />
  );
};

export default LabelInputReadOnly;
