// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

// Functional Imports
import { useLocation } from "react-router-dom";
import Colors from "../../utils/Colors";

// Component Imports
import DetailedScreeningSubTab from "./DetailedScreeningSubTab";
import PreScreeningSubTab from "./PreScreeningSubTab";
import Skillmatrix from "./skillmatrix";
import Appdetail from "./Appdetail";
import Functions from "../../utils/Functions";

interface DetailtabsectionProps {
  appData?: any;
}

const Detailtabsection: FC<DetailtabsectionProps> = (props) => {
  const [value, setValue] = useState("1");
  const location = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (tab === "interviews") {
      setValue("2");
    }
  }, [location.search]);

  const preScreeningTabData = Functions.ValueRetrieve.parseJson(
    props.appData?.basicPreScreeningQuestions
  );

  const detailedScreeningTabData = Functions.ValueRetrieve.parseJson(
    props.appData?.detailedPreScreeningQuestions
  );

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange}>
            <Tab
              label="Basic"
              value="1"
              style={{
                color: value === "1" ? Colors.Blue7 : Colors.Black1,
                borderBottom:
                  value === "1" ? "4px solid " + Colors.Blue7 : undefined,
              }}
            />
            {!!preScreeningTabData && (
              <Tab
                label="Pre-Screening"
                value="2"
                style={{
                  color: value === "2" ? Colors.Blue7 : Colors.Black1,
                  borderBottom:
                    value === "2" ? "4px solid " + Colors.Blue7 : undefined,
                }}
              />
            )}
            {!!detailedScreeningTabData && (
              <Tab
                label="Detailed Screening"
                value="3"
                style={{
                  color: value === "3" ? Colors.Blue7 : Colors.Black1,
                  borderBottom:
                    value === "3" ? "4px solid " + Colors.Blue7 : undefined,
                }}
              />
            )}
            {props.appData.skills && (
              <Tab
                label="Skill Matrix"
                value="4"
                style={{
                  color: value === "4" ? Colors.Blue7 : Colors.Black1,
                  borderBottom:
                    value === "4" ? "4px solid " + Colors.Blue7 : undefined,
                }}
              />
            )}
          </TabList>
        </Box>

        <TabPanel sx={{ background: "#fff", mr: 2 }} value="1">
          <Appdetail appData={props.appData} />
        </TabPanel>

        <TabPanel sx={{ background: "#fff", mr: 2 }} value="2">
          <PreScreeningSubTab
            basicPreScreeningQuestions={preScreeningTabData}
          />
        </TabPanel>

        <TabPanel sx={{ background: "#fff", mr: 2 }} value="3">
          <DetailedScreeningSubTab
            detailedPreScreeningQuestions={detailedScreeningTabData}
          />
        </TabPanel>

        {props.appData.skills && (
          <TabPanel sx={{ background: "#fff", mr: 2 }} value="4">
            <Skillmatrix skills={props.appData.skills} />
          </TabPanel>
        )}
      </TabContext>
    </Box>
  );
};

export default Detailtabsection;
