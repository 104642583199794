// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import Paths from "../../routes/Paths";

// Component Imports
import ClearAllFiltersButton from "../../common/ClearAllFiltersButton";
import FilterPopupUsers from "./FilterPopupUsers";
import ButtonFilled from "../../common/ButtonFilled";
import FilterButton from "../../common/FilterButton";
import AddUserPopup from "./AddUserPopup";
import Breadcrumbs from "../../common/Breadcrumbs";
import FilterTile from "../../common/FilterTile";
import UsersList from "./UsersList";
import API from "../../api/API";
import ENUMS from "../../utils/Enums";
import Functions from "../../utils/Functions";

interface UsersProps {
  isTesting?: boolean;
}

const Users: FC<UsersProps> = (props) => {
  // Table Related
  const [usersList, setUsersList] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [sortModel, setSortModel] = useState<any>([]);
  const [updateDataAgain, setUpdateDataAgain] = useState(false);

  // Popup Related
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [filtersOpen, setFiltersOpen] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState<any>(
    ENUMS.USER_FILTERS.FIRST_NAME
  );
  const [searchText, setSearchText] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  const [selectedFiltersForRequest, setSelectedFiltersForRequest] =
    useState<any>([]);

  // Filter Related
  const [firstNameFilters, setFirstNameFilters] = useState<any>([]);
  const [firstNameData, setFirstNameData] = useState<any>([]);

  const [lastNameFilters, setLastNameFilters] = useState<any>([]);
  const [lastNameData, setLastNameData] = useState<any>([]);

  const [emailIdFilters, setEmailIdFilters] = useState<any>([]);
  const [emailIdData, setEmailIdData] = useState<any>([]);

  const [statusFilters, setStatusFilters] = useState<any>([]);
  const [statusData, setStatusData] = useState<any>([]);

  const [roleFilters, setRoleFilters] = useState<any>([]);
  const [roleData, setRoleData] = useState<any>([]);

  // Add User
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);

  useEffect(() => {
    if (props.isTesting) {
      handleClick({});
      onApply();
      removeFilter(ENUMS.USER_FILTERS.FIRST_NAME);
      removeFilter(ENUMS.USER_FILTERS.LAST_NAME);
      removeFilter(ENUMS.USER_FILTERS.EMAIL_ID);
      removeFilter(ENUMS.USER_FILTERS.STATUS);
      removeFilter(ENUMS.USER_FILTERS.ROLE);
      onResetAll();
      onClearAllFilters();
    }
  }, []);

  useEffect(() => {
    getUsersList();
    updateFilterKeys();
  }, [updateDataAgain, pagination]);

  const getUsersList = () => {
    let sortOrder = "Descending";

    if (sortModel?.[0]?.sort === "asc") {
      sortOrder = "Ascending";
    }

    let sortField = Functions.ValueRetrieve.getSortingKeyForUsers(
      sortModel?.[0]?.field
    );

    const payload = {
      pageIndex: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      sortColumn: sortField,
      sortOrder: sortOrder,
      isSorted: true,
      firstNames: firstNameFilters,
      lastNames: lastNameFilters,
      emailIds: emailIdFilters,
      statuses: statusFilters,
      roles: roleFilters,
    };

    setUsersLoading(true);
    API.getUsers(payload).then((response) => {
      setUsersList(response.entityList);
      setUsersLoading(false);
      setUsersCount(Number(response.totalRecords));
    });
  };

  const handleClick = (event: any) => {
    if (filtersOpen) {
      setAnchorEl(null);
      setFiltersOpen(false);
    } else {
      setAnchorEl(event.currentTarget);
      setFiltersOpen(true);
    }
  };

  const updateFilterKeys = () => {
    let selectedFiltersForRequestPass = [];

    if (firstNameFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.USER_FILTERS.FIRST_NAME);
    }
    if (lastNameFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.USER_FILTERS.LAST_NAME);
    }
    if (emailIdFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.USER_FILTERS.EMAIL_ID);
    }
    if (statusFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.USER_FILTERS.STATUS);
    }
    if (roleFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.USER_FILTERS.ROLE);
    }

    if (selectedFiltersForRequestPass.length > 0) {
      setSelectedFiltersForRequest([...selectedFiltersForRequestPass]);
    } else {
      setSelectedFiltersForRequest([]);
    }
  };

  const onApply = () => {
    setFiltersOpen(false);
    setUpdateDataAgain(!updateDataAgain);
  };

  const removeFilter = (filter: string) => {
    if (filter === ENUMS.USER_FILTERS.FIRST_NAME) {
      setFirstNameFilters([]);
    } else if (filter === ENUMS.USER_FILTERS.LAST_NAME) {
      setLastNameFilters([]);
    } else if (filter === ENUMS.USER_FILTERS.EMAIL_ID) {
      setEmailIdFilters([]);
    } else if (filter === ENUMS.USER_FILTERS.ROLE) {
      setRoleFilters([]);
    } else if (filter === ENUMS.USER_FILTERS.STATUS) {
      setStatusFilters([]);
    }
    setUpdateDataAgain(!updateDataAgain);
  };

  const onResetAll = () => {
    setFirstNameFilters([]);
    setLastNameFilters([]);
    setEmailIdFilters([]);
    setRoleFilters([]);
    setStatusFilters([]);
  };

  const onClearAllFilters = () => {
    onResetAll();
    setUpdateDataAgain(!updateDataAgain);
  };

  return (
    <Box
      data-testid="users-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          pt: 2,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
            mt: 1,
            mb: 0.5,
          }}
          path={[{ path: Paths.DASHBOARD, name: "Home" }, { name: "Users" }]}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterPopupUsers
            isExpanded={filtersOpen}
            setIsExpanded={setFiltersOpen}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            searchText={searchText}
            setSearchText={setSearchText}
            dataLoading={dataLoading}
            setDataLoading={setDataLoading}
            anchorEl={anchorEl}
            onApply={onApply}
            onResetAll={onResetAll}
            firstNameFilters={firstNameFilters}
            setFirstNameFilters={setFirstNameFilters}
            firstNameData={firstNameData}
            setFirstNameData={setFirstNameData}
            lastNameFilters={lastNameFilters}
            setLastNameFilters={setLastNameFilters}
            lastNameData={lastNameData}
            setLastNameData={setLastNameData}
            emailIdFilters={emailIdFilters}
            setEmailIdFilters={setEmailIdFilters}
            emailIdData={emailIdData}
            setEmailIdData={setEmailIdData}
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
            statusData={statusData}
            setStatusData={setStatusData}
            roleFilters={roleFilters}
            setRoleFilters={setRoleFilters}
            roleData={roleData}
            setRoleData={setRoleData}
          />
          <FilterButton onClick={handleClick} />
          {selectedFiltersForRequest.map((item: any) => (
            <FilterTile
              key={item}
              title={item}
              onClick={() => removeFilter(item)}
            />
          ))}
          {selectedFiltersForRequest.length > 0 && (
            <ClearAllFiltersButton onClick={onClearAllFilters} />
          )}
        </Box>

        <Box>
          <ButtonFilled
            title="Add User"
            sx={{
              mr: 5,
            }}
            onClick={() => setAddUserModalOpen(true)}
          />
        </Box>
      </Box>
      <UsersList
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        tableData={usersList}
        tableLoading={usersLoading}
        totalEntries={usersCount}
        setPagination={setPagination}
        sortModel={sortModel}
        setSortModel={setSortModel}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />

      <AddUserPopup
        open={addUserModalOpen}
        setOpen={setAddUserModalOpen}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
    </Box>
  );
};

export default Users;
