// React Imports
import { FC, ReactElement, cloneElement } from "react";

// UI Imports
import { Box, Button, SxProps, Tooltip, Typography } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import Text from "../../common/Text";

// Component Imports

interface SideBarTileProps {
  icon: ReactElement;
  label: string;
  onClick?: any;
  isGroup?: boolean;
  open?: boolean;
  isActive?: boolean;
  testId?: string;
  sx?: SxProps;
  menulabel?: any;
  tileclass?: any;
  menuexpand?: any;
}

const SideBarTile: FC<SideBarTileProps> = (props) => {
  return (
    <div className={props.tileclass}>
      <Box
        onClick={props.onClick}
        sx={{
          ...props.sx,
          backgroundColor: props.isActive ? Colors.White : Colors.Blue7,
          ":hover": {
            color: "#fff!important",
          },
        }}
      >
        {!props.menuexpand ? (
          <Tooltip
            title={props.label}
            placement="right"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <Button 
              data-testid={props.testId}
              sx={{
                display: "flex",
                height: 63,
                textTransform: "none",
                p: 3,
                borderRadius: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {cloneElement(props.icon, {
                  color: props.isActive ? Colors.Blue7 : Colors.White,
                })}
              </Box>
            </Button>
          </Tooltip>
        ) : (
          <Button
            data-testid={props.testId}
            sx={{
              display: "flex",
              height: 63,
              textTransform: "none",
              p: 3,
              borderRadius: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cloneElement(props.icon, {
                color: props.isActive ? Colors.Blue7 : Colors.White,
              })}
            </Box>
          </Button>
        )}
        <Typography
          color={props.isActive ? Colors.Blue7 : Colors.White}
          style={{ cursor: "pointer" }}
          onClick={props.onClick}
        >
          {props.menulabel}
        </Typography>
      </Box>
    </div>
  );
};

export default SideBarTile;
