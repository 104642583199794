// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import { Link } from "react-router-dom";
import Colors from "../../utils/Colors";
import Paths from "../../routes/Paths";

// Component Imports
import ApplicationHierarchyList from "./ApplicationHierarchyList";
import HierarchyRow from "./HierarchyRow";
import Text from "../../common/Text";

interface HierarchyBlockProps {
  jobId?: any;
  jobTitle?: any;
  jobLocation?: any;
  jobPostedDate?: any;
  jobApplications?: any;

  setUpdateDataAgain?: any;
  updateDataAgain?: boolean;
  setSortModel?: any;
  onShare?: any;
  onHire?: any;
  onReject?: any;
  onResumeOpen?: any;
  onScheduleInterviewOpen?: any;
  noOfApplications?: number;

  expanded?: boolean;
  setExpanded?: any;
  showMore?: boolean;

  testId?: string;
}

const HierarchyBlock: FC<HierarchyBlockProps> = (props) => {

  let jobApplications = [];

  if (props.jobApplications) {
    jobApplications = props.jobApplications;
  }

  return (
    <Box
      sx={{
        mt: 2,
        width: "99%",
        backgroundColor: Colors.White,
        pb: props.expanded ? 2 : 0,
      }}
      data-testid={props.testId}
    >
      <HierarchyRow
        jobId={props.jobId}
        jobTitle={props.jobTitle}
        jobLocation={props.jobLocation}
        jobPostedDate={props.jobPostedDate}
        noOfApplication={props.noOfApplications}
        expanded={props.expanded}
        setExpanded={props.setExpanded}
      />
      <ApplicationHierarchyList
        pageIndex={0}
        pageSize={0}
        tableData={jobApplications}
        hideFooter
        sx={{
          display: props.expanded ? "block" : "none",
          height: "auto",
          width: "calc(101% - 72px)",
        }}
        updateDataAgain={props.updateDataAgain}
        setUpdateDataAgain={props.setUpdateDataAgain}
        onShare={props.onShare}
        onHire={props.onHire}
        onReject={props.onReject}
        onResumeOpen={props.onResumeOpen}
        onScheduleInterviewOpen={props.onScheduleInterviewOpen}
      />
      {props.expanded && props.showMore && (
        <Box
          sx={{
            width: "calc(100% - 32px)",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mr: 4,
            my: 1,
          }}
        >
          <Link
            to={
              Paths.JOB +
              Paths.VIEW_JOB +
              "/" +
              props.jobId +
              "?opentab=applications"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text fontWeight={600} fontSize={16} color={Colors.Blue1}>
              Show more
            </Text>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default HierarchyBlock;
