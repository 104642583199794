// React Imports
import { FC } from "react";

// UI Imports
import { Dialog, DialogContent, Typography, Box } from "@mui/material";

// Functional Imports
import STRINGS from "../../utils/Strings";
import ButtonFilled from "../../common/ButtonFilled";
import ButtonOutlined from "../../common/ButtonOutlined";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "400px",
          height: "210px",
          boxShadow: "0px 6px 16px 0px #00000014",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography
          sx={{
            width: "336px",
            height: "44px",
            fontFamily: "Roboto",
            fontSize: "19px",
            fontWeight: 500,
            lineHeight: "22.27px",
            textAlign: "center",
            color: "#000000",
          }}
        >
          {STRINGS.PROPOSE_TIME_SLOTS.CONFIRMATION_TEXT}
        </Typography>
        <Typography
          sx={{
            width: "336px",
            height: "16px",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.41px",
            textAlign: "center",
            color: "#000000",
            marginTop: "8px",
          }}
        >
          {STRINGS.PROPOSE_TIME_SLOTS.INFO_TEXT}
        </Typography>
        <Box sx={{ display: "flex", gap: "16px", marginTop: "16px" }}>
          <ButtonOutlined 
            title="Yes"
            onClick={onConfirm}
            sx={{
              border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SELECTED_SLOT_COLOR}`,
              textTransform: "capitalize",
            }}
          />
          <ButtonFilled
            title="No"
            onClick={onClose}
            sx={{
              backgroundColor: STRINGS.PROPOSE_TIME_SLOTS.SELECTED_SLOT_COLOR,
              color: "#FFFFFF",
              width: 60
            }}
          />
          
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
