// React Imports
import { FC, useEffect } from "react";

import {
  Box,
  Grid,
  Modal,
  IconButton,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import Text from "../../common/Text";
import LabelInput from "../../common/LabelInput";
import ButtonFilled from "../../common/ButtonFilled";
import Colors from "../../utils/Colors";
import STRINGS from "../../utils/Strings";
import Loader from "../../common/Loader";
import htmlParser from "html-react-parser";
import Functions from "../../utils/Functions";

interface PreviewProps {
  open: boolean;
  setOpen?: any;
  formik?: any;
  loading?: any;
  isTesting?: boolean
}

const Preview: FC<PreviewProps> = (props) => {

  useEffect(() => {
    if (props.isTesting) {
      handleClose()
    }
  }, [])

  const handleClose = () => {
    props.setOpen(false);
  };

  const toCamelCase = (str: any) => {
    return str
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const formatNumberWithCommas = (value: any) => {
    if (!value) return '';
    const cleanValue = value.toString().replace(/,/g, '');
    const [whole, decimal] = cleanValue.split('.');
    const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimal !== undefined ? `${formattedWhole}.${decimal}` : formattedWhole;
  };

  return (
    <Modal open={props.open} onClose={handleClose}>
      <Box
        data-testid="Preview-popup"
        sx={{
          position: "absolute",
          width: "80%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 10,
          backgroundColor: Colors.White,
          borderRadius: 1,
          p: 4,
          height: "80vh",
          overflow: "auto",
        }}
      >
        {props.loading && <Loader />}

        <Box
          sx={{
            height: 60,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fontWeight={700} fontSize={24}>
            Preview
          </Text>
          <IconButton onClick={handleClose} data-testid="close-button">
            <IoClose />
          </IconButton>
        </Box>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.JOB_TITLE}
              textFieldProps={{
                variant: "standard",
                sx: {
                  mt: 0.5,
                },
                inputProps: {
                  readOnly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                  },
                },
                value: toCamelCase(props.formik?.values?.jobTitle || ''),
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.MODE_OF_WORK}
              textFieldProps={{
                variant: "standard",
                sx: {
                  mt: 0.5,
                },
                inputProps: {
                  readOnly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                  },
                },
                value: Functions.ValueRetrieve.getModeofWork(
                  props.formik?.values?.modeofwork
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.SKILL_SET}
              textFieldProps={{
                variant: "standard",
                sx: {
                  mt: 0.5,
                },
                inputProps: {
                  readOnly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                  },
                },
                value: props.formik?.values?.skillSet,
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.NUMBER_OF_OPENING}
              textFieldProps={{
                variant: "standard",
                sx: {
                  mt: 0.5,
                },
                inputProps: {
                  readOnly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                  },
                },
                value: props.formik?.values?.numberOfOpenings,
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.EMPLOYMENT_TYPE}
              textFieldProps={{
                variant: "standard",
                sx: {
                  mt: 0.5,
                },
                inputProps: {
                  readOnly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                  },
                },
                value: Functions.ValueRetrieve.getJobType(
                  props.formik?.values?.jobType
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.INTERVIEW_MODE}
              textFieldProps={{
                variant: "standard",
                sx: {
                  mt: 0.5,
                },
                inputProps: {
                  readOnly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                  },
                },
                value: Functions.ValueRetrieve.getInterviewType(
                  props.formik?.values?.interviewtype
                ),

              }}
            />
          </Grid>
          {(props.formik?.values?.jobType == "C" ||
            props.formik?.values?.jobType == "R") && (
              <Grid item xs={4} md={4} mt={2}>
                <LabelInput
                  label={STRINGS.CREATE_JOB.BILL_RATE}
                  textFieldProps={{
                    variant: "standard",
                    sx: {
                      mt: 0.5,
                    },
                    inputProps: {
                      readOnly: true,
                      sx: {
                        backgroundColor: Colors.White,
                        fontSize: 16,
                        fontWeight: 700,
                        fontFamily: 'Roboto',
                      },
                    },
                    value: `$${formatNumberWithCommas(props.formik?.values?.billRate)}`,
                  }}
                />
              </Grid>
            )}
          {(props.formik?.values?.jobType == "F" ||
            props.formik?.values?.jobType == "R") && (
              <Grid item xs={4} md={4} mt={2}>
                <LabelInput
                  label={STRINGS.CREATE_JOB.ANNUALSALARY}
                  textFieldProps={{
                    variant: "standard",
                    sx: {
                      mt: 0.5,
                    },
                    inputProps: {
                      readOnly: true,
                      sx: {
                        backgroundColor: Colors.White,
                        fontSize: 16,
                        fontWeight: 700,
                        fontFamily: 'Roboto',
                      },
                    },
                    value: `$${formatNumberWithCommas(props.formik?.values?.salaryRange)}`,
                  }}
                />
              </Grid>
            )}
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.COUNTRY}
              textFieldProps={{
                variant: "standard",
                sx: {
                  mt: 0.5,
                },
                inputProps: {
                  readOnly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                  },
                },
                value: props.formik?.values?.countryName,
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.STATE}
              textFieldProps={{
                variant: "standard",
                sx: {
                  mt: 0.5,
                },
                inputProps: {
                  readOnly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                  },
                },
                value: props.formik?.values?.stateName,
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.CITY}
              textFieldProps={{
                variant: "standard",
                sx: {
                  mt: 0.5,
                },
                inputProps: {
                  readOnly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                  },
                },
                value: props.formik?.values?.city,
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}>
            <LabelInput
              label={STRINGS.CREATE_JOB.ZIP}
              textFieldProps={{
                variant: "standard",
                sx: {
                  mt: 0.5,
                },
                inputProps: {
                  readOnly: true,
                  sx: {
                    backgroundColor: Colors.White,
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                  },
                },
                value: props.formik?.values?.zipCode,
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={2}></Grid>
          <Grid item xs={12} md={12} mt={2}>
            <Text>{STRINGS.CREATE_JOB.JOD_DESCRIPTION}</Text>
            <Card variant="outlined" sx={{ Width: "100%", mb: 3, mt: 2, backgroundColor: "#F9FAFC" }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {htmlParser(props.formik?.values?.jobDescription)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonFilled title="Publish" onClick={props.formik.handleSubmit} />
        </Box>
      </Box>
    </Modal>
  );
};

export default Preview;
