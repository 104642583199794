// React Imports
import { FC } from "react";

// UI Imports
import { Box, Switch, SwitchProps, SxProps } from "@mui/material";

// Functional Imports
import styled from "@emotion/styled";
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../Text";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "100ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: Colors.Blue7,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: Colors.Blue7,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {},
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
  },
}));

interface LabelSwitchProps {
  label: string;
  labelTextColor?: string
  required?: boolean;
  sx?: SxProps;
  value?: string;
  onChange?: any;
  switchProps?: SwitchProps;
  switchText?: string;

  testId?: string;
}

const LabelSwitch: FC<LabelSwitchProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        width: "23%",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.3,
        }}
      >
        <Text color={props.labelTextColor}>{props.label}</Text>
        {props.required && <Text color={Colors.Red2}>*</Text>}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: 2,
          marginTop: 1.5,
        }}
      >
        <Text
          style={{
            width: 60,
          }}
          fontSize={16}
        >
          {props.switchText}
        </Text>

        <IOSSwitch
          sx={{
            ml: -1,
          }}
          {...props.switchProps}
        />
      </Box>
    </Box>
  );
};

export default LabelSwitch;

LabelSwitch.defaultProps = {
  labelTextColor: Colors.Grey9,
};
