// React Imports
import { FC } from "react";

// UI Imports
import { Paper, SxProps } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import {  useNavigate } from "react-router-dom";

// Functional Imports
import ENUMS from "../../utils/Enums";
import Colors from "../../utils/Colors";

// Component Imports
import HoverActionTile from "../../common/HoverActionTile";
import Paths from "../../routes/Paths";

interface HoverActionUsersProps {
  id?: string;
  status?: string;
  onClickActiveInactive?: any;
  sx?: SxProps;
}

const HoverActionUsers: FC<HoverActionUsersProps> = (props) => {
  const navigate = useNavigate();
  return (
    <Paper
      data-testid="hover-actions-users-popup"
      sx={{
        backgroundColor: Colors.White,
        width: 200,
        borderRadius: 1,
        ...props.sx,
      }}
    >
      <HoverActionTile
        testId="view-details-btn"
        icon={<GiHamburgerMenu size={20} color={Colors.Blue7} />}
        title="View Details"
        onClick={() => {
          navigate(Paths.USER+"/"+props.id);
        }}
      />
      <HoverActionTile
        testId="edit-btn"
        icon={<MdEdit size={20} color={Colors.Blue7} />}
        title="Edit"
        onClick={() => {
          navigate(Paths.USEREDIT+"/"+props.id);
        }}
      />
      {(props.status === ENUMS.JOB_STATUS_TYPES.ACTIVE ||
        props.status === ENUMS.JOB_STATUS_TYPES.INACTIVE) && (
        <HoverActionTile
          onClick={props.onClickActiveInactive}
          icon={
            props.status === ENUMS.JOB_STATUS_TYPES.ACTIVE ? (
              <IoClose size={20} color={Colors.Blue7} />
            ) : (
              <FaCheck size={20} color={Colors.Blue7} />
            )
          }
          title={
            props.status === ENUMS.JOB_STATUS_TYPES.ACTIVE
              ? "Mark as inactive"
              : "Mark as active"
          }
        />
      )}
    </Paper>
  );
};

export default HoverActionUsers;
