// React Imports
import { FC } from "react";

// Router Imports
import { Routes as Router, Route, Navigate } from "react-router-dom";
import Paths from "./Paths";

// Old Page Imports

// New Page Imports
import CreatePassword from "../components/CreatePassword";
import ContactUs from "../components/ContactUs";
import LoginPage from "../components/LoginPage";
import ForgotPassword from "../components/ForgotPassword";

interface NoAuthRoutesProps {}

const NoAuthRoutes: FC<NoAuthRoutesProps> = (props) => {
  return (
    <div data-testid="no-auth-routes">
      <Router>
        <Route
          path={Paths.EMPLOYER_ACCOUNT_CREATE_PASSWORD}
          element={<CreatePassword />}
        />
        <Route path={Paths.CONTACT_US + "/:type"} element={<ContactUs />} />
        <Route path={Paths.LOGIN} element={<LoginPage />} />
        <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to={Paths.LOGIN} />} />
       

      </Router>
    </div>
  );
};

export default NoAuthRoutes;
