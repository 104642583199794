// React Imports
import React, { FC, useEffect } from "react";

// UI Imports
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Box, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Loader from "../../common/Loader";
import Text from "../../common/Text";
import Paths from "../../routes/Paths";
import { Link } from "react-router-dom";
import StatusChip from "./StatusChip";
import ActionTileProjectList from "./ActionTileProjectList";
import Functions from "../../utils/Functions";
import EmptyList from "../Applications/EmptyList";

interface ProjectListProps {
  disableVirtualization?: boolean;
  sx?: SxProps;
  tableData?: any;
  tableLoading?: boolean;
  pageIndex: number;
  pageSize: number;
  setPagination?: any;
  totalEntries?: number;
  sortModel?: any;
  setUpdateDataAgain?: any;
  updateDataAgain?: boolean;
  setSortModel?: any;

  testId?: string;
  isTesting?: boolean;
}

const ProjectList: FC<ProjectListProps> = (props) => {
  const projectListColumns: any = [
    {
      field: "actions",
      headerName: "Actions",
      ...headerText,
      renderCell: (params: GridRenderCellParams) => (
        <ActionTileProjectList
          {...params}
          updateDataAgain={props.updateDataAgain}
          setUpdateDataAgain={props.setUpdateDataAgain}
        />
      ),
      minWidth: 60,
      flex: 0.5,
      ...commonColumnProps,
      sortable: false,
    },
    ...columns,
  ];

  useEffect(() => {
    if (props.isTesting) {
      renderLoader();
      renderEmptyRow();
    }
  }, []);

  return (
    <Box data-testid="project-list">
      {props.tableData?.length === 0 && !props.tableLoading && (
        <EmptyList
          sx={{
            p: 12,
          }}
        />
      )}
      {(props.tableData?.length > 0 || props.tableLoading) && (
        <DataGrid
          loading={props.tableLoading}
          disableVirtualization={props.disableVirtualization}
          data-testid={props.testId}
          slots={{
            loadingOverlay: renderLoader,
            noRowsOverlay: renderEmptyRow,
          }}
          slotProps={{
            pagination: {
              labelRowsPerPage: "",
            },
          }}
          sx={{
            backgroundColor: Colors.White,
            width: "calc(100vw - 140px)",
            height: "calc(100vh - 200px)",
            marginTop: 1.5,
            ml: 4,
            ...props.sx,
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          paginationModel={{
            page: props.pageIndex,
            pageSize: props.pageSize,
          }}
          paginationMode="server"
          onPaginationModelChange={(ev) => {
            props.setPagination({
              pageIndex: ev.page,
              pageSize: ev.pageSize,
            });
          }}
          rows={props.tableData}
          getRowId={(row) => row.id}
          columns={projectListColumns}
          rowCount={props.totalEntries}
          rowSelection={false}
          sortModel={props.sortModel}
          sortingMode="server"
          onSortModelChange={(ev) => {
            props.setUpdateDataAgain(!props.updateDataAgain);
            props.setSortModel(ev);
          }}
        />
      )}
    </Box>
  );
};

export default ProjectList;

const headerText = {
  renderHeader: (params: GridColumnHeaderParams) => {
    return (
      <Text fontSize={16} fontWeight={700}>
        {params.colDef?.headerName}
      </Text>
    );
  },
};

const cellRender = {
  renderCell: (params: GridRenderCellParams) => {
    return <Text>{params.row?.[params.field]}</Text>;
  },
};

const commonColumnProps = {
  disableColumnMenu: true,
};

const columns: GridColDef[] = [
  {
    field: "projectName",
    headerName: "Project Name",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Link to={Paths.PROJECT + "/" + params.row?.id}>
          <Text color={Colors.Blue1}>
            {params.row?.projectName + " - " + params.row?.projectId}
          </Text>
        </Link>
      );
    },
    minWidth: 240,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "employeeName",
    headerName: "Worker Name",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Link to={""}>
          <Text color={Colors.Blue1}>{params.row?.employeeName}</Text>
        </Link>
      );
    },
    minWidth: 140,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "startDate",
    headerName: "Start Date",
    ...headerText,
    valueGetter: (params) => {
      return Functions.DateFunctions.formatDateObjectOrNA(params.value);
    },
    minWidth: 100,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "endDate",
    headerName: "End Date",
    ...headerText,
    valueGetter: (params) => {
      return Functions.DateFunctions.formatDateObjectOrNA(params.value);
    },
    minWidth: 100,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "billRate",
    headerName: "Bill Rate",
    ...headerText,
    valueGetter: (params) => {
      return Functions.Sonar.returnStringWithDollarNotNull(params.value);
    },
    minWidth: 100,
    flex: 1,
    ...commonColumnProps,
    sortable: false,
  },
  {
    field: "otBillRate",
    headerName: "OT Bill Rate",
    ...headerText,
    valueGetter: (params) => {
      return Functions.Sonar.returnStringWithDollarNotNull(params.value);
    },
    minWidth: 100,
    flex: 1,
    ...commonColumnProps,
    sortable: false,
  },
  {
    field: "accountManager",
    headerName: "Account Manager",
    ...headerText,
    ...cellRender,
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "projectStatus",
    headerName: "Status",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return <StatusChip type={params.row?.projectStatus} />;
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
];

const renderLoader = () => <Loader />;
const renderEmptyRow = () => (
  <Box
    sx={{
      mt: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Text>No data found</Text>
  </Box>
);
