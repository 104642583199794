// React Imports
import { FC, useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import Functions from "../../utils/Functions";
import STRINGS from "../../utils/Strings";
import Colors from "../../utils/Colors";
import Toasts from "../../utils/Toasts";
import Paths from "../../routes/Paths";
import API from "../../api/API";

// Component Imports
import ButtonFilled from "../../common/ButtonFilled";
import Breadcrumbs from "../../common/Breadcrumbs";
import Tabsection from "./Tabsection";
import Loader from "../../common/Loader";
import Text from "../../common/Text";

interface ViewJobProps {}

const ViewJob: FC<ViewJobProps> = (props) => {
  const [searchParams] = useSearchParams();

  const Params = useParams();
  const navigate = useNavigate();
  const [jobData, setJobData] = useState<any>({});
  const [skilldata, setSkilldata] = useState<any>({});
  const opentab = searchParams.get("opentab");

  const [loading, setLoading] = useState(false);
  const jobID = Params.jobid;

  const [tabValue, setTabValue] = useState("1");

  useEffect(() => {
    setLoading(true);
    API.getJobByID(jobID)
      .then((response) => {
        if (response?.status === 200) {
          setJobData(response?.entity);
          setSkilldata(
            Functions.ValueRetrieve.getSkillandLicenses(
              response?.entity?.manualData
            )
          );

          if (opentab === "applications") {
            setTabValue("2");
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        Toasts.error(e);
        setJobData({});
        setLoading(false);
      });
  }, [jobID]);
  let postingdate = Functions.DateFunctions.formatDateObject(
    jobData.postingDate,
    "MM/DD/YYYY"
  );

  return (
    <Box
      data-testid="view-job-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "scroll",
      }}
    >
      {loading && <Loader />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 2,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
          }}
          path={[
            { path: Paths.DASHBOARD, name: "Home" },
            { path: Paths.JOBS, name: "Jobs" },
            { name: jobData.id },
          ]}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          p: 4,
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          position: "relative",
        }}
      >
        <Text fontWeight={700} fontSize={24}>
          {STRINGS.COMMONSTR.DETAIL}
        </Text>
        <ButtonFilled
          title={STRINGS.COMMONSTR.EDIT}
          onClick={() => {
            navigate(Paths.JOB + Paths.EDIT_JOB + "/" + Params.jobid);
          }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.White,
          ml: 4,
          mr: 4,
          p: 2,
          pl: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={14}>
              {STRINGS.CREATE_JOB.JOB_ID}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {jobData.id}
            </Text>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={14}>
              {STRINGS.CREATE_JOB.HIRING_MANAGER}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {jobData?.hiringManagerName}
            </Text>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={14}>
              {STRINGS.COMMONSTR.STATUS}
            </Text>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flax-start",
                alignItems: "center",
                p: 0,
              }}
            >
              <div className={jobData.jobStatus}>
                <Text fontWeight={400} fontSize={14}>
                  {jobData.jobStatus}
                </Text>
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={14}>
              {STRINGS.CREATE_JOB.JOB_LOCATION}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {jobData.city}, {jobData.stateCode}, {jobData.countryCode}
            </Text>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={14}>
              {STRINGS.CREATE_JOB.JOB_POSTED}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {postingdate}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          p: 2,
          pl: 4,
          pt: 0,
        }}
      >
        <Tabsection
          jobdata={jobData}
          skilldata={skilldata}
          appjobid={jobData.stafflineJobId}
          value={tabValue}
          setValue={setTabValue}
        />
      </Box>
    </Box>
  );
};

export default ViewJob;
