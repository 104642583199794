// React Imports
import { FC } from 'react';

// UI Imports
import {
  Box,
  Grid,
} from "@mui/material";
import Colors from '../../utils/Colors';
import Dashboard from './Dashboard';
import InterviewSidebar from '../InterviewSidebar/InterviewSidebar';

// Functional Imports

// Component Imports

interface DashboardlayoutProps { }

const Dashboardlayout: FC<DashboardlayoutProps> = (props) => {
  return (<Box
    data-testid="dashboard-layout-page"
    sx={{
        width:"100vw",
      height: "90vh",

      backgroundColor: Colors.LightWhite,
    }}
  >

    <Grid container spacing={2} gap={2}>
      <Grid item xs={8.5}>
        <Dashboard />
      </Grid>
      <Grid
        item
      >
        <InterviewSidebar />
      </Grid>

    </Grid>
  </Box>);
};

export default Dashboardlayout;