// React Imports
import React, { FC } from "react";

// UI Imports
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Box, SxProps } from "@mui/material";

// Functional Imports
import { Link } from "react-router-dom";
import Colors from "../../utils/Colors";

// Component Imports
import ActionTileUsers from "./ActionTileUsers";
import StatusChipJobs from "../AllJobs/StatusChipJobs";
import EmptyList from "../Applications/EmptyList";
import Loader from "../../common/Loader";
import Text from "../../common/Text";
import Paths from "../../routes/Paths";

interface UsersListProps {
  disableVirtualization?: boolean;
  sx?: SxProps;
  tableData?: any;
  tableLoading?: boolean;
  pageIndex: number;
  pageSize: number;
  setPagination?: any;
  totalEntries?: number;
  sortModel?: any;
  setUpdateDataAgain?: any;
  updateDataAgain?: boolean;
  setSortModel?: any;
  onShare?: any;
  onHire?: any;
  onReject?: any;
  onResumeOpen?: any;
  onScheduleInterviewOpen?: any;
  hideFooter?: boolean;

  testId?: string;
}

const UsersList: FC<UsersListProps> = (props) => {
  const usersListColumns = [
    {
      field: "actions",
      headerName: "Actions",
      ...headerText,
      renderCell: (params: GridRenderCellParams) => (
        <ActionTileUsers
          {...params}
          updateDataAgain={props.updateDataAgain}
          setUpdateDataAgain={props.setUpdateDataAgain}
        />
      ),
      width: 120,
      ...commonColumnProps,
      sortable: false,
    },
    ...columns,
  ];

  return (
    <Box data-testid="users-list">
      {props.tableData?.length === 0 && !props.tableLoading && (
        <EmptyList
          sx={{
            p: 12,
          }}
        />
      )}
      {(props.tableData?.length > 0 || props.tableLoading) && (
        <DataGrid
          loading={props.tableLoading}
          disableVirtualization={props.disableVirtualization}
          data-testid={props.testId}
          slots={{
            loadingOverlay: renderLoader,
          }}
          slotProps={{
            pagination: {
              labelRowsPerPage: "",
            },
          }}
          sx={{
            backgroundColor: Colors.White,
            width: "calc(95vw - 42px)",
            height: "calc(90vh - 120px)",
            marginTop: 1.5,
            ml: 4,
            ...props.sx,
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          paginationModel={{
            page: props.pageIndex,
            pageSize: props.pageSize,
          }}
          paginationMode="server"
          onPaginationModelChange={(ev) => {
            props.setPagination({
              pageIndex: ev.page,
              pageSize: ev.pageSize,
            });
          }}
          rows={props.tableData}
          getRowId={(row) => row.id}
          columns={usersListColumns}
          rowCount={props.totalEntries}
          hideFooter={props.hideFooter}
          rowSelection={false}
          sortModel={props.sortModel}
          sortingMode="server"
          onSortModelChange={(ev) => {
            props.setUpdateDataAgain(!props.updateDataAgain);
            props.setSortModel(ev);
          }}
        />
      )}
    </Box>
  );
};

export default UsersList;

const headerText = {
  renderHeader: (params: GridColumnHeaderParams) => {
    return (
      <Text fontSize={16} fontWeight={700}>
        {params.colDef?.headerName}
      </Text>
    );
  },
};

const cellRender = {
  renderCell: (params: GridRenderCellParams) => {
    return <Text>{params.row?.[params.field]}</Text>;
  },
};

const commonColumnProps = {
  disableColumnMenu: true,
};

const columns: GridColDef[] = [
  {
    field: "username",
    headerName: "Name",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (        
        <Link to={Paths.USER + "/" + params.row?.id}>
          <Text color={Colors.Blue1}>
            {params.row?.firstName + " " + params.row?.lastName}
          </Text>
        </Link>
      );
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
    sortable: false,
  },
  {
    field: "firstName",
    headerName: "First Name",
    ...headerText,
    minWidth: 140,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    ...headerText,
    ...cellRender,
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },  
  {
    field: "emailId",
    headerName: "Email ID",
    ...headerText,
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params: GridRenderCellParams) => {
      return <StatusChipJobs type={params.row?.status} />;
    },
    ...headerText,
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "role",
    headerName: "Role",
    ...headerText,
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
];

const renderLoader = () => <Loader />;
