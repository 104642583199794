// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";
import { IoClose, IoShareSocialOutline } from "react-icons/io5";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaHandshake } from "react-icons/fa";

// Functional Imports
import { useNavigate } from "react-router-dom";
import Paths from "../../routes/Paths";
import ENUMS from "../../utils/Enums";
import Colors from "../../utils/Colors";

// Component Imports
import HoverActionTile from "../../common/HoverActionTile";

interface HoverActionsProps {
  id?: string;
  isExpanded: boolean;
  setIsExpanded?: any;
  onApply?: any;
  onShare?: any;
  onHire?: any;
  onReject?: any;
  updateDataAgain?: any;
  setUpdateDataAgain?: any;
  onScheduleInterviewOpen?: any;
  resumeVLNStatus?: string;
  candidateName?: any;
  candidateEmail?: any;
  currentAvailabilities?: any;

  isTesting?: boolean;
}

const HoverActions: FC<HoverActionsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        data-testid="hover-actions-popup"
        sx={{
          backgroundColor: Colors.White,
          width: 200,
          borderRadius: 1,
        }}
      >
        <HoverActionTile
          testId="view-details-btn"
          onClick={() => {
            navigate(Paths.APPLICATIONS + "/" + props.id);
          }}
          icon={<GiHamburgerMenu size={20} color={Colors.Blue7} />}
          title="View Details"
        />
        {props.resumeVLNStatus ===
          ENUMS.APPLICATION_STATUS_TYPES.PENDING_REVIEW && (
          <HoverActionTile
            testId="schedule-interview-btn"
            icon={<RiCalendarScheduleLine size={20} color={Colors.Blue7} />}
            title="Schedule Interview"
            onClick={() =>
              props.onScheduleInterviewOpen(
                props.resumeVLNStatus,
                props.currentAvailabilities,
                props.candidateName,
                props.candidateEmail,
                props.id
              )
            }
          />
        )}
        {(props.resumeVLNStatus ===
          ENUMS.APPLICATION_STATUS_TYPES.INTERVIEW_REQUESTED ||
          props.resumeVLNStatus ===
            ENUMS.APPLICATION_STATUS_TYPES.INTERVIEW_SCHEDULED) && (
          <>
            <HoverActionTile
              testId="modify-slot-btn"
              icon={<RiCalendarScheduleLine size={20} color={Colors.Blue7} />}
              title="Schedule Interview"
              onClick={() =>
                props.onScheduleInterviewOpen(
                  props.resumeVLNStatus,
                  props.currentAvailabilities,
                  props.candidateName,
                  props.candidateEmail,
                  props.id
                )
              }
            />
            <HoverActionTile
              icon={<FaHandshake size={20} color={Colors.Blue7} />}
              title="Hire"
              onClick={props.onHire}
            />
          </>
        )}
        <HoverActionTile
          icon={<IoShareSocialOutline size={20} color={Colors.Blue7} />}
          title="Share"
          onClick={props.onShare}
        />
        {(props.resumeVLNStatus ===
          ENUMS.APPLICATION_STATUS_TYPES.INTERVIEW_REQUESTED ||
          props.resumeVLNStatus ===
            ENUMS.APPLICATION_STATUS_TYPES.INTERVIEW_SCHEDULED ||
          props.resumeVLNStatus ===
            ENUMS.APPLICATION_STATUS_TYPES.PENDING_REVIEW) && (
          <HoverActionTile
            icon={<IoClose size={20} color={Colors.Blue7} />}
            title="Reject"
            onClick={props.onReject}
          />
        )}
      </Box>
    </>
  );
};

export default HoverActions;
