// React Imports
import { FC } from "react";

// UI Imports
import { Box, Button, IconButton, SxProps } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";

// Functional Imports
import Colors from "../../utils/Colors";
import Images from "../../utils/Images";

// Component Imports
import Text from "../../common/Text";

interface ClientPurchaseOrderProps {
  sx?: SxProps;
  isUploaded?: boolean;
}

const ClientPurchaseOrder: FC<ClientPurchaseOrderProps> = (props) => {
  return (
    <Box
      sx={{
        width: "23%",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.3,
        }}
      >
        <Text color={Colors.Grey9}>Client purchase order</Text>
      </Box>
      {!props.isUploaded && <UploadFileForCPO />}
      {props.isUploaded && <FileDetailsForCPO />}
    </Box>
  );
};

export default ClientPurchaseOrder;

interface UploadFileForCPOProps {}

export const UploadFileForCPO: FC<UploadFileForCPOProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 85,
        backgroundColor: Colors.LightBlue5,
        border: "2px dashed " + Colors.DarkBlue3,
        borderRadius: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 3,
      }}
    >
      <Box
        component={"img"}
        src={Images.UploadIcon}
        sx={{
          width: 48,
          height: 38,
        }}
      />
      <Text
        style={{
          width: "30%",
        }}
        color={Colors.Blue7}
      >
        Drag and Drop files here
      </Text>
      <Button
        variant="outlined"
        sx={{
          textTransform: "none",
        }}
      >
        <Text fontWeight={700} color={Colors.Blue7}>
          Browse file
        </Text>
      </Button>
    </Box>
  );
};

interface FileDetailsForCPOProps {}

export const FileDetailsForCPO: FC<FileDetailsForCPOProps> = (props) => {
  return (
    <Box
      sx={{
        height: 40,
        width: "100%",
        border: "1px solid " + Colors.Grey14,
        borderRadius: 1,
        backgroundColor: Colors.LightBlue5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pl: 1.25,
      }}
    >
      <Text fontWeight={600}>Order number 22322</Text>
      <IconButton>
        <RiDeleteBin5Line color={Colors.Black1} />
      </IconButton>
    </Box>
  );
};
